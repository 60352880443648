import cx from 'classnames';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { customStyles } from '@/elements/Select/Select';
import { LoadingMessage } from '@/elements/Select/SelectBase';
import InputTitle from './InputTitle';
import { randomNumber } from '@/pages/Dashboard2/utils';
import { ASYNC_SEARCH_TIMEOUT } from '@/config/const';

export const NoOptionsMessage = props => {
  let message = 'Нет данных';

  if (!props.selectProps.inputValue) {
    message = 'Начните вводить имя';
  } else if (props.selectProps.inputValue.length <= props.selectProps.minLength) {
    message = `Для поиска введите не менее ${props.selectProps.minLength + 1} символов`;
  }

  return (
    <div
      {...props.innerProps}
      style={props.getStyles('noOptionsMessage', props)}
    >
      {message}
    </div>
  );
};

export default class UserSearch extends React.Component<{
  onChange?: (item: any) => void;
  loadOptions: any;
  [other: string]: any;
}, any> {
  minLength: any;

  constructor(props) {
    super(props);

    this.state = {
      inputId: 0,
    }

    this.minLength = props.minLength === 0 ? 0 : props.minLength || 2;
  }

  onChange = (e: any) => {
    this.props.onChange(e);
  };

  promiseOptions = async (inputValue) => {
    const inputId = randomNumber();
    this.setState({ inputId });

    if (inputValue.length <= this.minLength) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      setTimeout(() => {
        if (this.state.inputId !== inputId) {
          return resolve(null);
        }

        return resolve(this.props.loadOptions(inputValue, this.props));
      }, ASYNC_SEARCH_TIMEOUT);
    });
  };

  render() {
    return (
      <React.Fragment>
        <InputTitle
          required={this.props.required}
          isClearable={this.props.isClearable}
          tooltip={this.props.tooltip}
          label={this.props.label}
          value={this.props.value}
          className={cx(this.props.titleClassName, 'input-select')}
          description={this.props.description}
        >
          <AsyncSelect
            {...this.props}
            styles={customStyles}
            // @ts-ignore ts-migrate(2322) FIXME: Type '{ styles: { input: (provided: any) => any; m... Remove this comment to see the full error message
            minLength={this.minLength}
            className={cx("select-rt", this.props.className)}
            classNamePrefix={cx("select-rt")}
            cacheOptions
            components={{ LoadingMessage, NoOptionsMessage }}
            defaultOptions={[this.props.defaultValue].filter((x) => x)}
            value={this.props.value}
            loadOptions={this.promiseOptions}
            onChange={this.onChange}
            isDisabled={this.props.disabled}
            isClearable={this.props.isClearable}
            openMenuOnFocus={true}
            placeholder={this.props.placeholder || ""}
            menuPortalTarget={this.props.isPortal ? document.querySelector("#select-portal") : null}
          />
        </InputTitle>
      </React.Fragment>
    );
  }
}
