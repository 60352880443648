import React from 'react';

const Loader = () => {
  return (
    <div className="content">
      <div className="loadingio-spinner-spinner-utmq1230pj">
        <div className="ldio-duexmvz2gq4">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default Loader;
