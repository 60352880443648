import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getBasicNewProjectData } from '@/actions/getBasicNewProjectData';
import { modalShow } from '@/actions/modal';
import {
  checkAccess,
  checkEdit,
  checkRight,
  getDictCodeById,
  getDictObj, getLicenseData, getParams, importExt,
  ppmCheckRight,
  updateByKeyGenerator
} from '@/utils';
import { useAppDispatch, useAppSelector } from './typedHooks';
import { addToast } from "@/actions/addToast";
import { addUserValue } from '@/actions/addUserValue';
import { inRange, isFunction } from 'lodash';
import { getPpmRequestData } from '@/actions/ppm/getPpmRequestData';
import qs from 'query-string';

const useExtImport = <T>(path) => {
  const [ext, setExt] = useState<T>(null);

  useEffect(() => {
    importExt(path).then(setExt);
  }, []);

  return ext;
}

const useIsLdapEnabled = () => {
  return useAppSelector(state => state.dict.properties.data['ldap.isEnabled']);
}

const useLicenseData = () => {
  return useAppSelector(getLicenseData);
}

const useCheckAccess = () => {
  return useAppSelector(state => checkAccess(state));
};

const useCheckRight = () => {
  return useAppSelector(state => checkRight(state));
};

const useCheckEdit = () => {
  return useAppSelector(state => checkEdit(state));
};

const useCheckEditProject = () => {
  return useAppSelector(state => state.NewProject.newProjectData.edit && checkAccess(state));
};

const useProjectStatusCode = () => {
  return useAppSelector(state => getDictObj(state.dict.status, state.NewProject.newProjectData.statusId))?.code;
};

const useCurrentUser = (): UserWeb => {
  return useAppSelector(state => state.Login.currentUser);
};

const useIsCurrentUserAdmin = (): boolean => {
  return useAppSelector(state => state.Login.currentUser?.rolesId
    ?.some(rid => getDictCodeById(state.dict.roles, rid) === 'ADMIN'));
};

const useProjectStageCode = () => {
  const [statusCode, setStatusCode] = useState<string>();
  const [projectStageId, stageDict] = useAppSelector(state => [
    state.NewProject.newProjectData.stageId,
    state.dict.stage
  ]);

  useEffect(() => {
    if (!stageDict.isLoad) {
      return;
    }

    const newStatusCode = getDictObj(stageDict, projectStageId)?.code;
    if (newStatusCode !== statusCode) {
      setStatusCode(newStatusCode);
    }
  }, [projectStageId, stageDict, setStatusCode, statusCode]);

  return statusCode;
};

const useStateMemorizing = <S>(initialState: S | (() => S), uniqKey = ''):
  [
    S,
    Dispatch<SetStateAction<S>>,
    () => void,
    () => void,
  ] => {
  const [data, setData] = useState(initialState);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const key = `${pathname}@${uniqKey}`;

  const storage = useAppSelector(state => state.UserStorage.local);
  const storedState = storage?.[key] || initialState;

  const setDataWithSave: Dispatch<SetStateAction<S>> = (setDataLocal) => {
    setData(prevState => {
      const newState = isFunction(setDataLocal) ? setDataLocal(prevState) : setDataLocal;
      dispatch(addUserValue(key, newState));
      return newState;
    });
  }

  const reset = () => setData(initialState);

  const restore = () => setData(storedState);

  return [data, setDataWithSave, restore, reset];
};

const useStateWithKey = <S>(defaultState: S | (() => S)):
  [
    S,
    (key: string, value: any) => void,
    Dispatch<SetStateAction<S>>
  ] => {
  const [data, setData] = useState(defaultState);
  const setDataByKey = useMemo(() => updateByKeyGenerator(setData), [setData]);

  return [data, setDataByKey, setData];
};

const useStateWithKeyMemorizing = <S>(defaultState: S | (() => S), uniqKey = ''):
  [
    S,
    (key: string, value: any) => void,
    Dispatch<SetStateAction<S>>,
    () => void,
    () => void,
  ] => {
  const [data, setData, restore, reset] = useStateMemorizing(defaultState, uniqKey);
  const setDataByKey = useMemo(() => updateByKeyGenerator(setData), [setData]);

  return [data, setDataByKey, setData, restore, reset];
};

const useUpdateProjectData = () => {
  const dispatch = useAppDispatch();
  return useCallback((projectVersionId) =>
    dispatch(getBasicNewProjectData(projectVersionId)), [dispatch]);
};

const useForceUpdate = (): [any, number] => {
  const [value, setValue] = useState(0);
  return [() => setValue(value => value + 1), value];
};

const useCreateJiraLink = () => {
  const jiraUrl = useAppSelector(state => state.dict.properties.data['jira.url']);

  return (jiraCode: string, isFromJira: boolean) => {
    if (isFromJira) {
      return `${jiraUrl}/browse/${jiraCode}`;
    }

    return `${jiraUrl}/projects/${jiraCode}/issues`;
  };
};

const useEffectLocation = (cb: React.EffectCallback, locations: string[]) => {
  const location = useLocation();
  const [oldLocation, setOldLocation] = useState(location.pathname);
  const regexpLocation = useMemo(() => {
    return new RegExp(`(${locations.join('|')})/?$`);
  }, [locations]);

  useEffect(() => {
    if (location.pathname === oldLocation) {
      return;
    }

    setOldLocation(location.pathname);

    if (regexpLocation.test(oldLocation)) {
      cb();
    }
  }, [location.pathname]);
};

const useShowModal = () => {
  const dispatch = useAppDispatch();
  return useCallback((message, withThrow = false, options = {}) => {
    dispatch(modalShow(message, options));
    if (withThrow) {
      throw message;
    }
  }, [dispatch]);
};

const useAddToast = () => {
  const dispatch = useAppDispatch();
  return useCallback((message, id) => {
    dispatch(addToast(message, id));
  }, [dispatch]);
}

//Хук-костыль для сафари, который не обновляет css при сворачивании/разворачивании
const useRefreshValOnSidebarChange = (val: string) => {
  const isOpenSidebar = useAppSelector(state => state.Sidebar.isOpenSidebar);

  const [value, setValue] = useState(val);
  useEffect(() => {
    setValue('');
    setTimeout(() => {
      setValue(val);
    }, 10);
  }, [isOpenSidebar, setValue]);

  return value;
}

const useKeyPress = (actionKey, action) => {
  const handler = ({key}) => {
    if (actionKey === key) {
      action();
    }
  };
  return useEffect(() => {
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  })
}

const useEscape = (action) => {
  return useKeyPress('Escape', action);
}

const useUpdatePpmRequestData = () => {
  const dispatch = useAppDispatch();
  return useCallback((requestId) =>
    dispatch(getPpmRequestData(requestId)), [dispatch]);
};

const usePpmCheckRight = () => {
  return useAppSelector(state => ppmCheckRight(state));
};

const useCurrentPage = ({ maxLength }): [number, any] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentFrameNumber, setCurrentFrameNumber] = useState(+(searchParams.get("page") || 0));

  const setCurrentFrameNumberGuard = useCallback((func: (oldValue: number) => number) => {
    setCurrentFrameNumber((oldValue: number) => {
      const newValue = func(oldValue);
      return inRange(newValue, maxLength) ? newValue : oldValue;
    });
  }, [setCurrentFrameNumber, maxLength]);

  const handleUserKeyPress = useCallback((e) => {
    const key = e.code;

    if (key === 'ArrowLeft') {
      setCurrentFrameNumberGuard(oldValue => oldValue - 1);
    }

    if (key === 'ArrowRight' || key === 'Space') {
      setCurrentFrameNumberGuard(oldValue => oldValue + 1);
    }

  }, [setCurrentFrameNumberGuard]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    const realPage = +(searchParams.get("page") || 0);
    if (currentFrameNumber !== realPage) {
      setSearchParams(qs.stringify({
        ...getParams(searchParams),
        page: currentFrameNumber
      }));
    }
  }, [currentFrameNumber, setSearchParams]);

  useEffect(() => {
    const realPage = +(searchParams.get("page") || 0);
    if (currentFrameNumber !== realPage) {
      setCurrentFrameNumber(() => realPage);
    }
  }, [searchParams]);

  return [currentFrameNumber, setCurrentFrameNumberGuard];
};

const useFontSize = (defaultValue = 14): [number, (item: number) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [fontSize, setFontSize] = useState(+(searchParams.get("fontSize") || defaultValue));

  useEffect(() => {
    setSearchParams(qs.stringify({
      ...getParams(searchParams),
      fontSize: fontSize
    }), {
      replace: true
    });
  }, [fontSize]);

  return [fontSize, setFontSize];
};

export {
  useExtImport,
  useIsLdapEnabled,
  useLicenseData,
  useCheckAccess,
  useCheckEditProject,
  useProjectStageCode,
  useStateMemorizing,
  useStateWithKey,
  useStateWithKeyMemorizing,
  useCheckRight,
  useUpdateProjectData,
  useForceUpdate,
  useCheckEdit,
  useProjectStatusCode,
  useCurrentUser,
  useIsCurrentUserAdmin,
  useCreateJiraLink,
  useEffectLocation,
  useShowModal,
  useAddToast,
  useRefreshValOnSidebarChange,
  useKeyPress,
  useEscape,
  useUpdatePpmRequestData,
  usePpmCheckRight,
  useCurrentPage,
  useFontSize,
};
