import service from '@/services';
import { SET_PPM_REQUEST_DATA } from '@/reducers/PpmRequest';

export const getPpmRequestData = (id, callback = undefined) => {
  return async (dispatch) => {
    const ppmRequestData = await service.get(`/ppm/request/${id}`);
    ppmRequestData.files = await service.get(`/file/ppmRequest/${id}`);

    dispatch({
      type: SET_PPM_REQUEST_DATA,
      payload: ppmRequestData,
    });

    if (callback) {
      callback(ppmRequestData);
    }

    return ppmRequestData;
  };
};
