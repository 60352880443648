import React, { useEffect, useState } from 'react';
import { FELIX_REST } from "@/config/const";
import service from "@/services";

const getFileInfo = (fileId) => service.get(`/file/info/${fileId}`)

const FireLink = ({
  fileId,
  fileName,
  className = undefined
}) => {
  const [fileInfo, setFileInfo] = useState({ externalLink: null });

  useEffect(() => {
    if (fileId) {
      getFileInfo(fileId).then(setFileInfo);
    }
  }, [fileId])

  return (
    <React.Fragment>
      <a href={fileInfo?.externalLink ? fileInfo?.externalLink : `${FELIX_REST}/file/${fileId}`}
         className={className}
         target='_blank'
         onClick={(e) => {e.stopPropagation()}}>{fileName}</a>
    </React.Fragment>
  )
}

export default FireLink
