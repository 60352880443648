import cx from "classnames";
import React from "react";

export const ProjectCreate = () => (
  <svg width="24" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 18.5H2C1.72386 18.5 1.5 18.2761 1.5 18V2C1.5 1.72386 1.72386 1.5 2 1.5H9.47374C9.60391 1.5 9.72895 1.55076 9.82228 1.6415L14.3485 6.04203C14.4454 6.13617 14.5 6.26548 14.5 6.40053V18C14.5 18.2761 14.2761 18.5 14 18.5ZM0 2C0 0.895431 0.895431 0 2 0H9.47374C9.99442 0 10.4946 0.203056 10.8679 0.56601L15.3942 4.96654C15.7815 5.3431 16 5.86033 16 6.40053V18C16 19.1046 15.1046 20 14 20H2C0.895431 20 0 19.1046 0 18V2ZM7.25 15V11.75H4V10.25H7.25V7H8.75V10.25H12V11.75H8.75V15H7.25Z"
      fill="none"
    />
  </svg>
);

export const ProjectCommon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 18.5H4.99999C4.17156 18.5 3.49999 17.8284 3.49999 17V7C3.49999 6.17157 4.17156 5.5 4.99999 5.5L11.25 5.5V18.5ZM12.75 18.5V5.5L19 5.5C19.8284 5.5 20.5 6.17157 20.5 7L20.5 17C20.5 17.8284 19.8284 18.5 19 18.5H12.75ZM4.99999 20C3.34313 20 1.99999 18.6569 1.99999 17V7C1.99999 5.34315 3.34314 4 4.99999 4H19C20.6569 4 22 5.34314 22 7V17C22 18.6569 20.6569 20 19 20H4.99999ZM18 9.5H15V8H18V9.5ZM15 12.75H18V11.25H15V12.75ZM15 16H18V14.5H15V16Z"
      fill="#101828"
    />
  </svg>
);

export const ProjectGoal = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 7.5C4.32843 7.5 5 6.82843 5 6C5 5.17157 4.32843 4.5 3.5 4.5C2.67157 4.5 2 5.17157 2 6C2 6.82843 2.67157 7.5 3.5 7.5ZM7 5.25H22V6.75H7V5.25ZM22 11.25H7V12.75H22V11.25ZM22 17.25H7V18.75H22V17.25ZM5 12C5 12.8284 4.32843 13.5 3.5 13.5C2.67157 13.5 2 12.8284 2 12C2 11.1716 2.67157 10.5 3.5 10.5C4.32843 10.5 5 11.1716 5 12ZM3.5 19.5C4.32843 19.5 5 18.8284 5 18C5 17.1716 4.32843 16.5 3.5 16.5C2.67157 16.5 2 17.1716 2 18C2 18.8284 2.67157 19.5 3.5 19.5Z"
      fill="#101828"
    />
  </svg>
);

export const ProjectTeam = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 6C18 6.82843 17.3284 7.5 16.5 7.5C15.6716 7.5 15 6.82843 15 6C15 5.17157 15.6716 4.5 16.5 4.5C17.3284 4.5 18 5.17157 18 6ZM19.5 6C19.5 7.65685 18.1569 9 16.5 9C14.8431 9 13.5 7.65685 13.5 6C13.5 4.34315 14.8431 3 16.5 3C18.1569 3 19.5 4.34315 19.5 6ZM9 12C9 12.8284 8.32843 13.5 7.5 13.5C6.67157 13.5 6 12.8284 6 12C6 11.1716 6.67157 10.5 7.5 10.5C8.32843 10.5 9 11.1716 9 12ZM10.5 12C10.5 13.6569 9.15685 15 7.5 15C5.84315 15 4.5 13.6569 4.5 12C4.5 10.3431 5.84315 9 7.5 9C9.15685 9 10.5 10.3431 10.5 12ZM11.5 21H13C13 17.9624 10.5376 15.5 7.5 15.5C4.46243 15.5 2 17.9624 2 21H3.5C3.5 18.7909 5.29086 17 7.5 17C9.70914 17 11.5 18.7909 11.5 21ZM13.9235 12.276C14.646 11.5535 15.6258 11.1476 16.6475 11.1476C17.6693 11.1476 18.6491 11.5535 19.3716 12.276C20.094 12.9984 20.4999 13.9783 20.4999 15H21.9999C21.9999 13.5805 21.436 12.2191 20.4322 11.2153C19.4285 10.2115 18.0671 9.64763 16.6475 9.64763C15.228 9.64763 13.8666 10.2115 12.8628 11.2153C11.8591 12.2191 11.2952 13.5805 11.2952 15H12.7952C12.7952 13.9783 13.201 12.9984 13.9235 12.276Z"
      fill="#101828"
    />
  </svg>
);

export const ProjectRisk = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47357 15.0106H10.9736V16.5106V19.7442V20.5396V21.4998V21.6686C10.9736 22.0152 11.4396 22.1274 11.5974 21.8188L11.6742 21.6686L12.1114 20.8137L12.4736 20.1055L17.3911 10.4895L17.4139 10.4449L17.7496 9.78857L17.9721 9.35332C18.0572 9.18697 17.9364 8.98949 17.7496 8.98949H17.2607H16.5236H16.4734H14.5263H13.0263V7.48949V4.25586V3.46039V2.50024V2.33148C13.0263 1.98488 12.5603 1.87264 12.4025 2.18123L12.3257 2.33148L11.8885 3.18633L11.5263 3.89457L6.60878 13.5106L6.58595 13.5552L6.25033 14.2115L6.02775 14.6467C5.94268 14.8131 6.06349 15.0106 6.25033 15.0106H6.73919H7.47632H7.52645H9.47357ZM8.29354 13.5106H10.9736H12.4736V15.0106V16.811L15.7064 10.4895H13.0263H11.5263V8.98949V7.18903L8.29354 13.5106Z"
      fill="#101828"
    />
  </svg>
);

export const ProjectBudget = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.26 13.78H12.04C15.04 13.78 16.96 12.12 16.96 9.38C16.96 6.64 15.04 5 12.04 5H7.58V12.3H6V13.78H7.58V15.6H6V17H7.58V19H9.26V17H14.74V15.6H9.26V13.78ZM9.26 6.48H12C14.2 6.48 15.28 7.58 15.28 9.38C15.28 11.2 14.2 12.3 12 12.3H9.26V6.48Z"
      fill="#101828"
    />
  </svg>
);

export const ProjectChildren = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18 20.5H6C5.72386 20.5 5.5 20.2761 5.5 20V4C5.5 3.72386 5.72386 3.5 6 3.5H13.4737C13.6039 3.5 13.7289 3.55076 13.8223 3.6415L18.3485 8.04203C18.4454 8.13617 18.5 8.26548 18.5 8.40053V20C18.5 20.2761 18.2761 20.5 18 20.5ZM4 4C4 2.89543 4.89543 2 6 2H13.4737C13.9944 2 14.4946 2.20306 14.8679 2.56601L19.3942 6.96654C19.7815 7.3431 20 7.86033 20 8.40053V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4ZM16 10.5H8V9H16V10.5ZM8 13.75H16V12.25H8V13.75ZM8 17H16V15.5H8V17Z" fill="#101828" />
  </svg>
);

export const ProjectChildrenProgram = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.0607 6.06066L12.6213 6.5H12H3.5V11H2V6C2 5.44772 2.44772 5 3 5H12L13.1213 3.87868C13.6839 3.31607 14.447 3 15.2426 3H21C21.5523 3 22 3.44772 22 4V18C22 19.6569 20.6569 21 19 21H12V19.5H19C19.8284 19.5 20.5 18.8284 20.5 18V4.5H15.2426C14.8448 4.5 14.4633 4.65803 14.182 4.93934L13.0607 6.06066ZM10 14.5H2V13H10V14.5ZM2 17.75H10V16.25H2V17.75ZM2 21H10V19.5H2V21Z" fill="#101828" />
  </svg>
);

export const ProjectWork = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 3L18.5 9C18.5 9.27614 18.2761 9.5 18 9.5L6 9.5C5.72386 9.5 5.5 9.27614 5.5 9L5.5 3C5.5 2.72386 5.72386 2.5 6 2.5L18 2.5C18.2761 2.5 18.5 2.72386 18.5 3ZM18 1C19.1046 1 20 1.89543 20 3L20 9C20 10.1046 19.1046 11 18 11L6 11C4.89543 11 4 10.1046 4 9L4 3C4 1.89543 4.89543 1 6 1L18 1ZM18.5 15L18.5 21C18.5 21.2761 18.2761 21.5 18 21.5L6 21.5C5.72386 21.5 5.5 21.2761 5.5 21L5.5 15C5.5 14.7239 5.72386 14.5 6 14.5L18 14.5C18.2761 14.5 18.5 14.7239 18.5 15ZM18 13C19.1046 13 20 13.8954 20 15L20 21C20 22.1046 19.1046 23 18 23L6 23C4.89543 23 4 22.1046 4 21L4 15C4 13.8954 4.89543 13 6 13L18 13Z"
      fill="#101828"
    />
  </svg>
);

export const ProjectMilestone = () => (
  <svg width="20" height="24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 120.98">
    <path className="cls-1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.46,90.44a30.26,30.26,0,0,1-6.64,5.5.93.93,0,0,1-1.07,0A37.48,37.48,0,0,1,6.5,87.72,29.39,29.39,0,0,1,.28,74.3,17.87,17.87,0,0,1,2.51,61.74,15.5,15.5,0,0,1,6,57.93,16.79,16.79,0,0,1,16.49,54a14.82,14.82,0,0,1,10,4.09,14.29,14.29,0,0,1,2.67,3.25c2.45,4,3,9.17,1.9,14.39a31.55,31.55,0,0,1-7.6,14.66Zm-7.07,10.73a9.89,9.89,0,0,1,9.19,6.21,3.27,3.27,0,0,1,.54,0h7.94a4,4,0,0,1,0,7.94H26.12a3.48,3.48,0,0,1-.73-.07,9.9,9.9,0,1,1-9-14ZM107.47,46.6a9.91,9.91,0,1,1-9.08,13.87H86a4,4,0,1,1,0-7.93H98.39a9.9,9.9,0,0,1,9.08-5.94ZM76.69,79.94a4,4,0,1,0,0,7.94h9.55a4,4,0,1,0,0-7.94ZM70.62,60.56a4,4,0,0,0-1.05-7.87,17.57,17.57,0,0,0-9.21,4.15,4,4,0,0,0,3.46,6.88,4.11,4.11,0,0,0,1.74-.88,9.65,9.65,0,0,1,5.06-2.28Zm-8,12.51A4,4,0,0,0,55,75.43l.18.54a17.72,17.72,0,0,0,5.45,7.84,4,4,0,0,0,5.06-6.12,9.83,9.83,0,0,1-3.11-4.62ZM49.93,107.34a4,4,0,0,0,0,7.94h7.94a4,4,0,1,0,0-7.94Zm23.82,0a4,4,0,0,0,0,7.94h7.94a4,4,0,1,0,0-7.94Zm22.55-.44a4,4,0,0,0-.12,7.52,3.92,3.92,0,0,0,2.53,0,17.64,17.64,0,0,0,8.35-5.67,4,4,0,1,0-6.15-5l-.2.23a9.81,9.81,0,0,1-4.41,2.89Zm5.78-13.68a4,4,0,0,0,7-3.68,17.91,17.91,0,0,0-7-7.27,4,4,0,1,0-3.94,6.89,9.93,9.93,0,0,1,3.86,4.06ZM114.75,36.4a30.65,30.65,0,0,1-6.65,5.5,1,1,0,0,1-1.07,0,37.39,37.39,0,0,1-9.24-8.25,29.44,29.44,0,0,1-6.22-13.42A17.81,17.81,0,0,1,93.8,7.7a15.32,15.32,0,0,1,3.44-3.82A16.73,16.73,0,0,1,107.78,0a15.39,15.39,0,0,1,12.67,7.33c2.45,4,3,9.18,1.9,14.39a31.62,31.62,0,0,1-7.6,14.67ZM107.08,8.14A7.86,7.86,0,1,1,99.22,16a7.85,7.85,0,0,1,7.86-7.86Zm-91.29,54A7.86,7.86,0,1,1,7.93,70a7.85,7.85,0,0,1,7.86-7.86Z"/>
  </svg>
);

export const ProjectKpi = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.49854 3V19.2512C4.49854 19.3893 4.61046 19.5012 4.74854 19.5012H6.99979V19.5V10C6.99979 9.44771 7.4475 9 7.99979 9H10.9998C11.5521 9 11.9998 9.44772 11.9998 10V19.5V19.5012H13.9998V19.5V6C13.9998 5.44772 14.4475 5 14.9998 5H17.9998C18.5521 5 18.9998 5.44772 18.9998 6V19.5V19.5012H20.9998V21.0012H4.74854C3.78204 21.0012 2.99854 20.2177 2.99854 19.2512V3H4.49854ZM8.49979 10.5V19.5H10.4998V10.5H8.49979ZM15.4998 19.5V6.5H17.4998V19.5H15.4998Z"
      fill="#101828"
    />
  </svg>
);

export const ProjectMbo = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4.5H18C18.8284 4.5 19.5 5.17157 19.5 6V18C19.5 18.8284 18.8284 19.5 18 19.5H6C5.17157 19.5 4.5 18.8284 4.5 18V6C4.5 5.17157 5.17157 4.5 6 4.5ZM3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6ZM13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15ZM14.5 15C14.5 16.3807 13.3807 17.5 12 17.5C10.6193 17.5 9.5 16.3807 9.5 15C9.5 13.6193 10.6193 12.5 12 12.5C13.3807 12.5 14.5 13.6193 14.5 15Z"
      fill="#101828"
    />
  </svg>
);

export const ProjectLesson = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 4.5H17.5V15H8C7.45357 15 6.94126 15.1461 6.5 15.4013V6C6.5 5.17157 7.17157 4.5 8 4.5V7.77841C8 7.94669 8.16291 8.06691 8.32372 8.01729L10 7.5L11.6763 8.01729C11.8371 8.06691 12 7.94669 12 7.77841V4.5ZM8 19.5C7.17157 19.5 6.5 18.8284 6.5 18C6.5 17.1716 7.17157 16.5 8 16.5H17.5V19.5H8ZM19 4V15V16.5V20C19 20.5523 18.5523 21 18 21H8C6.34315 21 5 19.6569 5 18V6C5 4.34315 6.34315 3 8 3H18C18.5523 3 19 3.44772 19 4Z" fill="#101828" />
  </svg>
);

export const ProjectDocument = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9958 8.69044C21.0515 7.20755 20.57 5.66114 19.3462 4.54743C17.0181 2.42875 13.6254 2.53414 11.662 4.5831L4.48577 12.0722C2.84189 13.721 2.65739 15.9746 3.40814 17.7812C4.15475 19.5778 5.85309 21 8.02122 21H8.99988L8.99988 19.4684H8.02122C6.51177 19.4684 5.29946 18.4828 4.75468 17.1718C4.21493 15.873 4.35697 14.3223 5.5071 13.1722L5.51275 13.1665L5.51829 13.1607L12.6998 5.66611C14.0779 4.228 16.5919 4.07309 18.3816 5.70177C19.2167 6.46174 19.5705 7.53059 19.5292 8.63043C19.4875 9.73939 19.0434 10.8252 18.35 11.5518L14.0859 15.8221C14.0858 15.8221 14.0858 15.8222 14.0857 15.8223C13.5651 16.3428 13.059 16.5113 12.6462 16.506C12.2246 16.5007 11.8391 16.314 11.56 16.0129C11.2811 15.7121 11.1178 15.3074 11.1295 14.8725C11.141 14.4465 11.3231 13.925 11.8514 13.3967L11.8547 13.3933L16.0335 9.1612L15.0113 8.06209L10.8341 12.2926C10.0667 13.0608 9.68609 13.9496 9.66238 14.8295C9.6389 15.7014 9.96853 16.4991 10.5052 17.0779C11.0417 17.6566 11.7945 18.0269 12.6283 18.0376C13.471 18.0483 14.3375 17.6918 15.1014 16.9279L15.1017 16.9276L19.372 12.6512L19.3774 12.6457L19.3828 12.6402C20.3572 11.6233 20.9405 10.1615 20.9958 8.69044Z"
      fill="#101828"
    />
  </svg>
);

export const ProjectVersion = () => (
  <svg width="24" height="24" viewBox="0 0 384 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 39.2 28.2 71.8 65.5 78.7-.8 17.2-5 30.4-12.7 40-17.5 21.8-53.1 25.2-90.7 28.7-28.2 2.6-57.4 5.4-80.4 16.9-3.4 1.7-6.7 3.6-9.7 5.7V158.4c36.5-7.4 64-39.7 64-78.4 0-44.2-35.8-80-80-80S0 35.8 0 80c0 38.7 27.5 71 64 78.4v195.2C27.5 361 0 393.3 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-36.9-24.9-67.9-58.9-77.2 5-9.6 12.3-14.6 19-18 17.5-8.8 42.5-11.2 68.9-13.7 42.6-4 86.7-8.1 112.7-40.5 12.4-15.5 19-35.5 19.8-60.7C357.3 214 384 182.1 384 144zM32 80c0-26.5 21.5-48 48-48s48 21.5 48 48-21.5 48-48 48-48-21.5-48-48zm96 352c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-26.4 21.4-47.9 47.8-48h.6c26.3.2 47.6 21.7 47.6 48zm187.8-241.5L304 192c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48c0 22.4-15.4 41.2-36.2 46.5z"
      fill="#101828"
    />
  </svg>
);

export const ProjectStatus = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.966 7.2V9.048H11.034V7.2H12.966ZM11.118 17V10.5H12.882V17H11.118Z"
      fill="#101828"
    />
  </svg>
);

export const ProjectAgreement = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.06006 8.9861L12.0078 12.9399L21.9351 3L22.9951 4.06133L12.5377 14.532C12.3971 14.6727 12.2064 14.7518 12.0076 14.7518C11.8088 14.7518 11.6182 14.6727 11.4776 14.5319L6.99992 10.0473L8.06006 8.9861ZM6 4.50003H16.5V3.00003H6C4.34315 3.00003 3 4.34318 3 6.00003V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18L21 12H19.5V18C19.5 18.8285 18.8284 19.5 18 19.5H6C5.17157 19.5 4.5 18.8285 4.5 18V6.00003C4.5 5.1716 5.17157 4.50003 6 4.50003Z"
      fill="#101828"
    />
  </svg>
);

export const EditIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2407 5.87002L17.2857 4.82803C16.9165 4.42522 16.2924 4.39329 15.884 4.75633L14.3213 6.14571L16.6194 8.68277L18.1714 7.28989C18.5854 6.91828 18.6166 6.28018 18.2407 5.87002ZM5.5 13.9882L13.2002 7.1424L15.5031 9.68469L7.90674 16.5025H5.5V13.9882ZM19.3465 4.85654L18.3915 3.81455C17.4686 2.80753 15.9083 2.72771 14.8874 3.6353L4.33557 13.0164C4.12212 13.2061 4 13.4781 4 13.7637V17.0025C4 17.5548 4.44772 18.0025 5 18.0025H8.09822C8.34478 18.0025 8.58266 17.9114 8.76616 17.7467L19.1733 8.40621C20.2084 7.47718 20.2863 5.88193 19.3465 4.85654ZM20 19.4999H4V20.9999H20V19.4999Z"
      fill="#101828"
    />
  </svg>
);

export const MailIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#7700FF">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier"> <title></title>
      <g id="Complete">
        <g id="mail">
          <g>
            <polyline fill="none" points="4 8.2 12 14.1 20 8.2" stroke="#7700FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></polyline>
            <rect fill="none" height="14" rx="2" ry="2" stroke="#7700FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" width="18" x="3" y="6.5"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const DeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M10 11V17" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14 11V17" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 7H20" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const CreateProjectButtonIcon = () => (
  <svg className="createIcon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 6.75V12H6.75V6.75H12V5.25H6.75V0H5.25V5.25H0V6.75H5.25Z"
      fill="#101828"
    />
  </svg>
);


export const UserIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3266 18.6244C19.2617 17.0664 20.5 14.6778 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 14.6778 4.73826 17.0664 6.67343 18.6244C7.2842 16.2525 9.43744 14.5 12 14.5C14.5626 14.5 16.7158 16.2525 17.3266 18.6244ZM15.9712 19.5172C15.7328 17.5357 14.0457 16 12 16C9.95426 16 8.26721 17.5357 8.02884 19.5172C9.21409 20.1447 10.5655 20.5 12 20.5C13.4345 20.5 14.7859 20.1447 15.9712 19.5172ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 11.5C12.8284 11.5 13.5 10.8284 13.5 10C13.5 9.17157 12.8284 8.5 12 8.5C11.1716 8.5 10.5 9.17157 10.5 10C10.5 10.8284 11.1716 11.5 12 11.5ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
      fill="#101828"
    />
  </svg>
);

export const PortfolioIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7158 3C9.35852 3 8.20421 3.9903 7.99782 5.33184L7.89503 6H4C2.89543 6 2 6.89543 2 8V18C2 19.6569 3.34315 21 5 21H19C20.6569 21 22 19.6569 22 18V8C22 6.89543 21.1046 6 20 6H16.105L16.0022 5.33184C15.7958 3.99031 14.6415 3 13.2842 3H10.7158ZM14.5873 6L14.5196 5.55993C14.4258 4.95014 13.9011 4.5 13.2842 4.5H10.7158C10.0989 4.5 9.57419 4.95014 9.48038 5.55993L9.41268 6H14.5873ZM4 7.5H20C20.2761 7.5 20.5 7.72386 20.5 8V10.9297C18.8456 11.9818 16.9613 12.684 15 13.0362V13C15 11.8954 14.1046 11 13 11H11C9.89543 11 9 11.8954 9 13V13.0362C7.03871 12.684 5.1544 11.9818 3.5 10.9297V8C3.5 7.72386 3.72386 7.5 4 7.5ZM9 14.5582C7.06675 14.2404 5.19401 13.6122 3.5 12.6736V18C3.5 18.8284 4.17157 19.5 5 19.5H19C19.8284 19.5 20.5 18.8284 20.5 18V12.6736C18.806 13.6122 16.9332 14.2404 15 14.5582V15C15 16.1046 14.1046 17 13 17H11C9.89543 17 9 16.1046 9 15V14.5582ZM11 12.5H13C13.2761 12.5 13.5 12.7239 13.5 13V15C13.5 15.2761 13.2761 15.5 13 15.5H11C10.7239 15.5 10.5 15.2761 10.5 15V13C10.5 12.7239 10.7239 12.5 11 12.5Z"
      fill="#101828"
    />
  </svg>
);

export const ArchiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4.5H6C5.72386 4.5 5.5 4.72386 5.5 5V6H18.5V5C18.5 4.72386 18.2761 4.5 18 4.5ZM20 6.26756V5C20 3.89543 19.1046 3 18 3H6C4.89543 3 4 3.89543 4 5V6.26756C3.4022 6.61337 3 7.25972 3 8V11C2.44772 11 2 11.4477 2 12V18C2 19.6569 3.34315 21 5 21H19C20.6569 21 22 19.6569 22 18V10C22 9.44772 21.5523 9 21 9V8C21 7.25972 20.5978 6.61337 20 6.26756ZM5 7.5H19C19.2761 7.5 19.5 7.72386 19.5 8V9H11.2426C10.447 9 9.68393 9.31607 9.12132 9.87868L8 11H4.5V8C4.5 7.72386 4.72386 7.5 5 7.5ZM8.62132 12.5L9.06066 12.0607L10.182 10.9393C10.4633 10.658 10.8448 10.5 11.2426 10.5H20.5V18C20.5 18.8284 19.8284 19.5 19 19.5H5C4.17157 19.5 3.5 18.8284 3.5 18V12.5H8H8.62132ZM14 14.25H18V12.75H14V14.25Z"
      fill="#101828"
    />
  </svg>
);

export const DashboardIcon = () => (
  <svg width="24" height="24" viewBox="0 0 544 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M527.79 288H290.5l158.03 158.03a16.51 16.51 0 0 0 11.62 4.81c3.82 0 7.62-1.35 10.57-4.13 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-67.91 124.12L367.76 320h140.88c-8.12 34.16-24.96 66-48.76 92.12zM224 288V50.71c0-8.83-7.18-16.21-15.74-16.21-.69 0-1.4.05-2.11.15C86.99 51.49-4.1 155.6.14 280.37 4.47 407.53 113.18 512 240.13 512c.98 0 1.93-.01 2.91-.02 50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288zm18.63 191.98l-2.51.02c-109.04 0-204.3-91.92-208-200.72C28.72 179.15 96.33 92.25 192 69.83v231.42l9.37 9.37 141.84 141.84c-30.56 17.62-64.96 27.08-100.58 27.52zM511.96 223.2C503.72 103.74 408.26 8.28 288.8.04c-.35-.03-.7-.04-1.04-.04C279.1 0 272 7.45 272 16.23V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM304 208V33.9c89.25 13.81 160.28 84.85 174.1 174.1H304z"
      fill="#101828"
    />
  </svg>
);

export const CommitteeIcon = () => (
  <svg width="24" height="24" viewBox="-1 -1 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 3L1.5 15C1.5 15.8284 2.17157 16.5 3 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V3C16.5 2.17157 15.8284 1.5 15 1.5L3 1.5C2.17157 1.5 1.5 2.17157 1.5 3ZM0 15C0 16.6569 1.34315 18 3 18H15C16.6569 18 18 16.6569 18 15V3C18 1.34315 16.6569 0 15 0H3C1.34315 0 0 1.34315 0 3V15ZM15 9.75H7V8.25H15V9.75ZM7 5.75H15V4.25H7V5.75ZM13 13.75H7V12.25H13V13.75ZM4.5 7.5C3.67157 7.5 3 8.17157 3 9C3 9.82843 3.67157 10.5 4.5 10.5C5.32843 10.5 6 9.82843 6 9C6 8.17157 5.32843 7.5 4.5 7.5ZM3 5C3 4.17157 3.67157 3.5 4.5 3.5C5.32843 3.5 6 4.17157 6 5C6 5.82843 5.32843 6.5 4.5 6.5C3.67157 6.5 3 5.82843 3 5ZM4.5 11.5C3.67157 11.5 3 12.1716 3 13C3 13.8284 3.67157 14.5 4.5 14.5C5.32843 14.5 6 13.8284 6 13C6 12.1716 5.32843 11.5 4.5 11.5Z"
      fill="#101828"
    />
  </svg>
);

export const AdminIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.5C2 7.17556 3.17741 8.57612 4.75 8.91946V22H6.25V8.91946C7.82259 8.57612 9 7.17556 9 5.5C9 3.567 7.433 2 5.5 2C3.567 2 2 3.567 2 5.5ZM12 16.5C13.1046 16.5 14 17.3954 14 18.5C14 19.6046 13.1046 20.5 12 20.5C10.8954 20.5 10 19.6046 10 18.5C10 17.3954 10.8954 16.5 12 16.5ZM12.75 15.0805C14.3226 15.4239 15.5 16.8244 15.5 18.5C15.5 20.433 13.933 22 12 22C10.067 22 8.5 20.433 8.5 18.5C8.5 16.8244 9.67741 15.4239 11.25 15.0805V2H12.75V15.0805ZM18.5 9C19.6046 9 20.5 9.89543 20.5 11C20.5 12.1046 19.6046 13 18.5 13C17.3954 13 16.5 12.1046 16.5 11C16.5 9.89543 17.3954 9 18.5 9ZM17.75 14.4195C16.1774 14.0761 15 12.6756 15 11C15 9.32444 16.1774 7.92388 17.75 7.58054V2H19.25V7.58054C20.8226 7.92388 22 9.32444 22 11C22 12.6756 20.8226 14.0761 19.25 14.4195V22H17.75V14.4195ZM5.5 3.5C6.60457 3.5 7.5 4.39543 7.5 5.5C7.5 6.60457 6.60457 7.5 5.5 7.5C4.39543 7.5 3.5 6.60457 3.5 5.5C3.5 4.39543 4.39543 3.5 5.5 3.5Z"
      fill="#101828"
    />
  </svg>
);

export const NotifyIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3788 16.5L16.7683 8.65106C16.5861 6.30819 14.6318 4.5 12.2819 4.5H11.7181C9.36818 4.5 7.41389 6.30819 7.23167 8.65105L6.6212 16.5L17.3788 16.5ZM5.73619 8.53474L5.11667 16.5L4 16.5V18L5 18H6.50453H8.12602C8.57006 19.7252 10.1362 21 12 21C13.8638 21 15.4299 19.7252 15.874 18H17.4955H19H20V16.5H18.8833L18.2638 8.53474C18.0208 5.41092 15.4151 3 12.2819 3H11.7181C8.58487 3 5.97915 5.41092 5.73619 8.53474ZM14.292 18L9.70802 18C10.0938 18.883 10.9748 19.5 12 19.5C13.0252 19.5 13.9062 18.883 14.292 18Z"
      fill="#101828"
    />
  </svg>
);

export const AgreementIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 1.5H12C12.2761 1.5 12.5 1.72386 12.5 2V2.5C12.5 2.77614 12.2761 3 12 3H10.4793H3.52069H2C1.72386 3 1.5 2.77614 1.5 2.5V2C1.5 1.72386 1.72386 1.5 2 1.5ZM2.25 4.5H2C0.89543 4.5 0 3.60457 0 2.5V2C0 0.89543 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2V2.5C14 3.60457 13.1046 4.5 12 4.5H11.75L11.2374 7.5754C11.0639 8.61679 10.4414 9.48657 9.58814 10C10.4414 10.5134 11.0639 11.3832 11.2374 12.4246L11.75 15.5H12C13.1046 15.5 14 16.3954 14 17.5V18C14 19.1046 13.1046 20 12 20H2C0.89543 20 0 19.1046 0 18V17.5C0 16.3954 0.895431 15.5 2 15.5L2.25 15.5L2.76257 12.4246C2.93613 11.3832 3.55859 10.5134 4.41186 10C3.55859 9.48657 2.93613 8.61679 2.76257 7.5754L2.25 4.5ZM3.77069 4.5L4.24216 7.3288C4.40289 8.29317 5.23727 9 6.21495 9H7.78506C8.76273 9 9.59711 8.29317 9.75784 7.3288L10.2293 4.5H3.77069ZM9.75784 12.6712L10.2293 15.5L3.77069 15.5L4.24216 12.6712C4.40288 11.7068 5.23727 11 6.21494 11H7.78506C8.76273 11 9.59711 11.7068 9.75784 12.6712ZM1.5 17.5C1.5 17.2239 1.72386 17 2 17H3.52069H10.4793H12C12.2761 17 12.5 17.2239 12.5 17.5V18C12.5 18.2761 12.2761 18.5 12 18.5H2C1.72386 18.5 1.5 18.2761 1.5 18V17.5Z"
      fill="#101828"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 11C17.5 14.5899 14.5899 17.5 11 17.5C7.41015 17.5 4.5 14.5899 4.5 11C4.5 7.41015 7.41015 4.5 11 4.5C14.5899 4.5 17.5 7.41015 17.5 11ZM16.1018 17.1624C14.717 18.3101 12.9391 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 12.9391 18.3101 14.717 17.1624 16.1018L20.9991 19.9384L19.9384 20.9991L16.1018 17.1624Z"
      fill="#101828"
    />
  </svg>
);

export const TurnMenuIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.44231 12.5308L13.9423 18.0002L15 16.9366L10.0348 11.999L15 7.06133L13.9423 5.99772L8.44231 11.4672C8.30075 11.608 8.22116 11.7993 8.22116 11.999C8.22116 12.1986 8.30075 12.39 8.44231 12.5308Z" fill="#101828" />
  </svg>
);

export const ExitIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.5H15V3H8C6.34315 3 5 4.34315 5 6V18C5 19.6569 6.34315 21 8 21H15V19.5H8C7.17157 19.5 6.5 18.8284 6.5 18V6C6.5 5.17157 7.17157 4.5 8 4.5ZM14.1125 15.1836L15.1716 16.2426L16.2322 15.182L18.7071 12.7071C19.0976 12.3166 19.0976 11.6834 18.7071 11.2929L16.2322 8.81802L15.1716 7.75736L14.1125 8.81641L16.5461 11.25L9 11.25V12.75L16.5461 12.75L14.1125 15.1836Z"
      fill="#101828"
    />
  </svg>
);

export const CloseReport = () => (
  <svg width="24" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3 0H4.5H9.5H11C12.6569 0 14 1.34315 14 3V15C14 16.6569 12.6569 18 11 18H3C1.34315 18 0 16.6569 0 15V3C0 1.34315 1.34315 0 3 0ZM11 1.5C11.8284 1.5 12.5 2.17157 12.5 3V15C12.5 15.8284 11.8284 16.5 11 16.5H3C2.17157 16.5 1.5 15.8284 1.5 15V3C1.5 2.17157 2.17157 1.5 3 1.5V2C3 3.10457 3.89543 4 5 4H9C10.1046 4 11 3.10457 11 2V1.5ZM11.0147 8.07075L6.51897 12.3797C6.22679 12.6598 5.76514 12.6575 5.47573 12.3746L3.00991 9.96422L4.05845 8.89157L6.00513 10.7945L9.97674 6.98784L11.0147 8.07075ZM4.5 1.5H9.5V2C9.5 2.27614 9.27614 2.5 9 2.5H5C4.72386 2.5 4.5 2.27614 4.5 2V1.5Z" fill="#101828" />
  </svg>
);

export const AppendIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 12.75V21H12.75V12.75H21V11.25H12.75V3H11.25V11.25H3V12.75H11.25Z"
        fill="#101828"
      />
    </g>
  </svg>
);

export const LinkIcon = ({ className = undefined }) => (
  <svg className={cx(className)} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.84277 0H5.84277V1.5H3.84277C3.01435 1.5 2.34277 2.17157 2.34277 3V9C2.34277 9.82843 3.01435 10.5 3.84277 10.5H9.84277C10.6712 10.5 11.3428 9.82843 11.3428 9V7H12.8428V9C12.8428 10.6569 11.4996 12 9.84277 12H3.84277C2.18592 12 0.842773 10.6569 0.842773 9V3C0.842773 1.34315 2.18592 0 3.84277 0ZM7.84282 1.5L10.2821 1.5L4.81243 6.96967L5.87309 8.03033L11.3428 2.56057L11.3428 5.00001H12.8428V0.750006C12.8428 0.335793 12.5071 6.96227e-06 12.0928 6.31685e-06L7.84281 0L7.84282 1.5Z" fill="#7700FF" />
  </svg>
);

export const WarningIcon = ({ className = undefined }) => (
  <svg className={cx(className)} width="14" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ff4f12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0z"></path>
      <line x1="12" y1="9" x2="12" y2="13"></line>
      <line x1="12" y1="17" x2="12.01" y2="17"></line>
    </g>
  </svg>
);


export const R12Icon = ({ className = undefined, isCorrected = false }) => (
  <div className={cx('r12-icon', className, {'corrected': isCorrected})}>
    {isCorrected ? 'R12_кор' : 'R12'}
  </div>
);

export const TimeTrackerIcon = () => (
  <svg className="stroke" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 7V12L15 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#101828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const KeyIcon = () => (
  <svg width="35" height="18" viewBox="0 0 46 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 11.5C23 5.14872 17.8513 -7.80304e-07 11.5 -5.02681e-07C5.14872 -2.25058e-07 -7.80304e-07 5.14873 -5.02681e-07 11.5C-2.25058e-07 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5ZM17.25 11.5C17.25 8.32436 14.6756 5.75 11.5 5.75C8.32436 5.75 5.75 8.32436 5.75 11.5C5.75 14.6756 8.32436 17.25 11.5 17.25C14.6756 17.25 17.25 14.6756 17.25 11.5Z" fill="black"/>
    <rect x="21.0835" y="14.375" width="5.75" height="24.9167" transform="rotate(-90 21.0835 14.375)" fill="black"/>
    <rect x="34.5" y="17.25" width="3.83333" height="9.58333" transform="rotate(-90 34.5 17.25)" fill="black"/>
  </svg>
);

export const HelpMeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 24" width="24" height="24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21,12.424V11A9,9,0,0,0,3,11v1.424A5,5,0,0,0,5,22a2,2,0,0,0,2-2V14a2,2,0,0,0-2-2V11a7,7,0,0,1,14,0v1a2,2,0,0,0-2,2v6H14a1,1,0,0,0,0,2h5a5,5,0,0,0,2-9.576ZM5,20H5a3,3,0,0,1,0-6Zm14,0V14a3,3,0,0,1,0,6Z"
      fill="#101828"/>
  </svg>)

export const RequiredAlertIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path opacity="0.7" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#FFB608"/>
  <path d="M11.2982 13.5625H12.6982L12.9222 10.602V7.19995H11.0742V10.602L11.2982 13.5625ZM11.0742 17H12.9222V15.068H11.0742V17Z" fill="white"/>
</svg>)

export const RequiredReadyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path opacity="0.8" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#00D359"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.561 9.1768L11.061 15.5704C10.7479 15.8784 10.2449 15.8763 9.93433 15.5657L6.93433 12.5657L8.0657 11.4343L10.5047 13.8733L16.439 8.03613L17.561 9.1768Z" fill="white"/>
  </svg>)

export const exportObject = {
  ProjectCreate,
  ProjectCommon,
  ProjectGoal,
  ProjectTeam,
  ProjectRisk,
  ProjectBudget,
  ProjectChildren,
  ProjectChildrenProgram,
  ProjectWork,
  ProjectMilestone,
  ProjectKpi,
  ProjectMbo,
  ProjectLesson,
  ProjectDocument,
  ProjectVersion,
  ProjectStatus,
  ProjectAgreement,
  EditIcon,
  CreateProjectButton: CreateProjectButtonIcon,
  UserIcon,
  PortfolioIcon,
  ArchiveIcon,
  DashboardIcon,
  CommitteeIcon,
  AdminIcon,
  NotifyIcon,
  AgreementIcon,
  SearchIcon,
  TurnMenuIcon,
  ExitIcon,
  CloseReport,
  LinkIcon,
  TimeTrackerIcon,
};

// не могу сообразить как это в один объект собрать шоб в двух местах не добавлять
export default exportObject;