import i18n from "i18next";

const noBenefitStatement = async (self) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.noBenefitStatement) {
        return []
      }

      return data.noBenefitStatement.map((item) => ({
        key: item.staticId,
        title: (_, i) => `Показатель ${i + 1}`,
        data: [
          {
            key: 'noBenefitDescription',
            title: i18n.t('businessCaseWeb.noBenefitDescription'),
            data: item.noBenefitDescription
          },
          {
            key: 'responsible',
            title: i18n.t('businessCaseWeb.responsible'),
            data: item.responsible.displayName
          },
        ]
      }))
    }
  },
])

export default noBenefitStatement;
