import { cloneDeep, keyBy, uniq } from 'lodash';
import { LinkTypes } from '@/components/Gantt/const';
import { WeekendUtilType } from '@/components/Gantt/util/dateUtil';
import {
  filterRemoved, findAndUpdate, findByFuncAndUpdate, flatten, getDateRangeSimple
} from '@/components/Gantt/util/utils';
import { checkGroup, checkMilestone } from '@/pages/CreateProject/Blocks/utils';
import { isEmptyValues, isNotEmptyValues, momentToSelectDate, parseDate } from '@/utils';
import { Moment } from 'moment/moment';

const findChildLinks = (links: GanttTableLink[], id: number): GanttTableLink[] => {
  return links.filter(item => item.fromId === id);
};
const findParentLinks = (links: GanttTableLink[], id: number): GanttTableLink[] => {
  return links.filter(item => item.toId === id);
};

const getDeltaDays = (type: LinkTypes, parentItem: GanttTableItem, item: GanttTableItem) => {
  const parentDateStart = parseDate(parentItem.dateStart);
  const parentDateEnd = checkMilestone(parentItem) ? parentDateStart : parseDate(parentItem.dateEnd);
  const dateStart = parseDate(item.dateStart);
  const dateEnd = checkMilestone(item) ? dateStart : parseDate(item.dateEnd);

  switch (type) {
    case LinkTypes.END_TO_START:
      return parentDateEnd ? parentDateEnd.diff(dateStart, 'day') + 1 : 0;
    case LinkTypes.START_TO_START:
      return parentDateStart ? parentDateStart.diff(dateStart, 'day') : 0;
    case LinkTypes.START_TO_END:
      return parentDateStart ? parentDateStart.diff(dateEnd, 'day') - 1 : 0;
    case LinkTypes.END_TO_END:
      return parentDateEnd ? parentDateEnd.diff(dateEnd, 'day') : 0;
  }
};

const getParentMoveDays = (type: LinkTypes, parentItem: GanttTableItem) => {
  const parentDateStartPrev = parseDate(parentItem.dateStartPrev);
  const parentDateEndPrev = checkMilestone(parentItem) ? parentDateStartPrev : parseDate(parentItem.dateEndPrev);
  const parentDateStart = parseDate(parentItem.dateStart);
  const parentDateEnd = checkMilestone(parentItem) ? parentDateStart : parseDate(parentItem.dateEnd);

  switch (type) {
    case LinkTypes.END_TO_START:
    case LinkTypes.END_TO_END:
      return parentDateEnd ? parentDateEnd.diff(parentDateEndPrev, 'day') : 0;
    case LinkTypes.START_TO_START:
    case LinkTypes.START_TO_END:
      return parentDateStart ? parentDateStart.diff(parentDateStartPrev, 'day'): 0;
  }
};

const getLagDays = (type: LinkTypes, parentItem: GanttTableItem, item: GanttTableItem, weekendUtil: WeekendUtilType) => {
  const parentDateStartPrev = parseDate(parentItem.dateStartPrev);
  const parentDateEndPrev = checkMilestone(parentItem) ? parentDateStartPrev : parseDate(parentItem.dateEndPrev);
  const dateStart = parseDate(item.dateStart);
  const dateEnd = checkMilestone(item) ? dateStart : parseDate(item.dateEnd);

  switch (type) {
    case LinkTypes.END_TO_START:
      return weekendUtil.getBetween(parentDateEndPrev, dateStart);
    case LinkTypes.START_TO_START:
      return weekendUtil.getBetween(parentDateStartPrev, dateStart);
    case LinkTypes.START_TO_END:
      return weekendUtil.getBetween(parentDateStartPrev, dateEnd);
    case LinkTypes.END_TO_END:
      return weekendUtil.getBetween(parentDateEndPrev, dateEnd);
  }
};

const getNewDateByLag = (type: LinkTypes, parentItem: GanttTableItem, lagDays: number, duration: number, weekendUtil: WeekendUtilType) => {
  const parentDateStart = parseDate(parentItem.dateStart);
  const parentDateEnd = checkMilestone(parentItem) ? parentDateStart : parseDate(parentItem.dateEnd);

  let start;
  let end;
  switch (type) {
    case LinkTypes.END_TO_START:
      start = parentDateEnd ? weekendUtil.getWorkDaysAfter(parentDateEnd, lagDays) : null;
      end = start ? weekendUtil.pad(weekendUtil.getDateEnd(start, duration)) : null;
      break;
    case LinkTypes.END_TO_END:
      end = parentDateEnd ? weekendUtil.getWorkDaysAfter(parentDateEnd, lagDays) : null;
      start = end ? weekendUtil.getDateStart(end, duration) : null;
      break;
    case LinkTypes.START_TO_START:
      start = parentDateStart ? weekendUtil.getWorkDaysAfter(parentDateStart, lagDays) : null;
      end = start ? weekendUtil.pad(weekendUtil.getDateEnd(start, duration)) : null;
      break;
    case LinkTypes.START_TO_END:
      end = parentDateStart ? weekendUtil.getWorkDaysAfter(parentDateStart, lagDays) : null;
      start = end ? weekendUtil.getDateStart(end, duration) : null;
      break;
  }

  return { start, end };
};

export const checkNotBeforeParent = (
  currentItem: GanttTableItem,
  data: GanttTableItem[],
  links: GanttTableLink[],
  weekendUtil: WeekendUtilType,
  isFromEditForm: boolean = false,
) => {
  const dataFlatten = keyBy(flatten(data), 'id');
  const parentLinks = findParentLinks(links, currentItem.id);
  parentLinks.forEach(link => {
    if (link.fromId === currentItem.id) {
      return;
    }

    const parent = dataFlatten[link.fromId];
    if (!parent) {
      return;
    }

    const linkCode = weekendUtil.getLinkTypeCodeById(link.typeId);
    const deltaDays = getDeltaDays(linkCode, parent, currentItem);
    if (deltaDays > 0) {
      const moveErrorText = isFromEditForm
        ? `Для сохранения удалите связь с блоком работ "${parent.name}", измените тип связи или переместите текущий блок работ.`
        : `Для перемещения блока работ удалите связь с блоком работ "${parent.name}" или измените тип связи.`;

      switch (linkCode) {
        case LinkTypes.END_TO_START:
          throw `Блок работ "${currentItem.name}" не может начинаться раньше окончания блока работ "${parent.name}".\n${moveErrorText}`;
        case LinkTypes.START_TO_START:
          throw `Блок работ "${currentItem.name}" не может начинаться раньше начала блока работ "${parent.name}".\n${moveErrorText}`;
        case LinkTypes.START_TO_END:
          throw `Блок работ "${currentItem.name}" не может заканчиваться раньше начала блока работ "${parent.name}".\n${moveErrorText}`;
        case LinkTypes.END_TO_END:
          throw `Блок работ "${currentItem.name}" не может заканчиваться раньше окончания блока работ "${parent.name}".\n${moveErrorText}`;
      }
    }
  });
}

export const fixByLinks = (
  id: number,
  oldData: GanttTableItem[],
  links: GanttTableLink[],
  weekendUtil: WeekendUtilType,
) => {
  const dataFlatten = keyBy(flatten(oldData), 'id');
  const beUpdate: Record<number, GanttTableItem> = {};

  const fixByLinksLocal = (currentItem: GanttTableItem) => {
    if (!currentItem) {
      return;
    }

    //Подвинем дочерние работы
    const childLinks = findChildLinks(links, currentItem.id);
    childLinks.forEach(link => {
      if (link.toId in beUpdate || link.toId === id) {
        return;
      }

      let isUpdate = false;
      const child = dataFlatten[link.toId];
      if (!child || child.isFromOtherProject) {
        return;
      }

      const linkCode = weekendUtil.getLinkTypeCodeById(link.typeId);
      const parentMoveDays = getParentMoveDays(linkCode, currentItem);
      const lagDays = getLagDays(linkCode, currentItem, child, weekendUtil);
      const deltaDays = getDeltaDays(linkCode, currentItem, child);

      if (deltaDays > 0 || parentMoveDays < 0) {
        isUpdate = true;
        const currentDateStart = parseDate(child.dateStart);
        const currentDateEnd = parseDate(child.dateEnd);
        let newDateStart: Moment;
        let newDateEnd: Moment;
        if (deltaDays > 0) { //Если родитель накладывается на работу, то двигаем работу за родителя
          newDateStart = weekendUtil.pad(currentDateStart.add(deltaDays, 'day'));
          newDateEnd = weekendUtil.pad(weekendUtil.getDateEnd(newDateStart, child.duration));
        } else if (parentMoveDays < 0) { // Если родителя подвинули назад, то двигаем работу тоже назад
          const newDates = getNewDateByLag(linkCode, currentItem, lagDays, child.duration, weekendUtil);
          newDateStart = newDates.start;
          newDateEnd = newDates.end;
        }
        beUpdate[child.id] = {
          ...child,
          dateStart: momentToSelectDate(newDateStart),
          dateEnd: momentToSelectDate(newDateEnd),
          dateStartPrev: currentDateStart,
          dateEndPrev: currentDateEnd,
        };
      }

      if (isUpdate) {
        fixByLinksLocal(beUpdate[link.toId]);
      }
    });
  };

  fixByLinksLocal(dataFlatten[id]);

  return findAndUpdate(oldData, Object.keys(beUpdate).map(item => +item), (item) => beUpdate[item.id]);
};

const mergeObjectWithArrays = (obj1, obj2) => {
  const result: Record<number, number[]> = {};

  [...Object.keys(obj1), ...Object.keys(obj2)].forEach(key => {
    result[key] = uniq([...(obj1[key] || []), ...(obj2[key] || [])]);
  });

  return result;
};

const getGroupsDeep = (oldData: GanttTableItem[], context = {}, result = {}): Record<number, number[]> => {
  if (isEmptyValues(oldData)) {
    return mergeObjectWithArrays(context, result);
  }

  const [head, ...tail] = oldData;

  Object.keys(context).forEach(key => {
    context[key].push(head.id);
  });

  if (isNotEmptyValues(head.subRows)) {
    const newContext = cloneDeep(context);
    newContext[head.id] ||= [];
    result = getGroupsDeep(head.subRows, newContext, result);
  }

  return getGroupsDeep(tail, context, mergeObjectWithArrays(context, result));
};

export const fixWorkInGroup = (oldData: GanttTableItem[]): GanttTableItem[] => {
  return findByFuncAndUpdate(oldData, () => true, (item, groupId) => ({
    ...item,
    workGroupId: groupId
  }));
};

export const fixGroup = (
  oldData: GanttTableItem[],
  weekendUtil: WeekendUtilType,
) => {
  const dataFlattenList = filterRemoved(flatten(oldData));
  const dataFlatten = keyBy(dataFlattenList, 'id');
  const beUpdate: Record<number, GanttTableItem> = {};

  const groups = getGroupsDeep(oldData);

  Object.entries(groups).forEach(([groupId, groupItemsId]) => {
    if (!dataFlatten[groupId]) {
      return;
    }

    const items = dataFlattenList.filter(item => !checkGroup(item) && groupItemsId.includes(item.id));
    const dateRange = getDateRangeSimple(items);

    if (!dateRange) {
      beUpdate[groupId] = {
        ...dataFlatten[groupId],
        duration: null,
        dateStart: null,
        dateEnd: null,
      };
      return;
    }

    const duration = weekendUtil.getDuration(dateRange.min, dateRange.max);

    beUpdate[groupId] = {
      ...dataFlatten[groupId],
      duration,
      dateStart: momentToSelectDate(dateRange.min),
      dateEnd: momentToSelectDate(dateRange.max),
    };
  });

  let result = findByFuncAndUpdate(oldData, (item) => beUpdate.hasOwnProperty(item.id), (item) => beUpdate[item.id]);
  result = fixWorkInGroup(result);

  return result;
};