import React from 'react';
import i18n from "i18next";
import moment from 'moment';
import cx from 'classnames';
import { useDeviceContext } from '@/context/DeviceContext';
import { InputNumField } from '@/elements/Input/InputNum';
import AcceptDelete from '@/components/Helper/AcceptDelete'
import { monthHead } from '@/pages/Report/TeamLoad';
import { isEmptyValues } from '@/utils';
import Message from '@/elements/Message';

const InputTable = ({
  value,
  onChange,
  max = 100,
  min = 0,
  readonly,
  className = undefined,
  ...props
}) => {
  return (
    <InputNumField
      className={cx("input-edit-felix no-padding", className)}
      value={value}
      onChange={onChange}
      readOnly={readonly}
      suffix="%"
      placeholder="%"
      min={min}
      max={max}
      {...props}
    />
  )
}

const monthsAll = monthHead.map(item => item.text);

const TeamLoadTable = ({ deleteRow = undefined, data, setData = undefined, readonly = false }) => {
  const { isMobile } = useDeviceContext();

  const changeYear = (index, newYear) => {
    setData(data.map((item, i) => {
      if (i !== index) {
        return item;
      }

      item.year = newYear;
      Object.keys(item.data).forEach(key => item.data[key].date.year(newYear));

      return item;
    }))
  };

  const changeValue = (index, month, value) => {
    setData(data.map((item, i) => {
      if (i !== index) {
        return item;
      }

      item.data[month] = {
        date: moment().year(item.year).month(month).startOf('month'),
        value
      }

      return item;
    }))
  }

  const getDataByMonth = (index, month) => data[index].data[month]?.value || '';
  const isOverload = (index, month) => data[index].data[month]?.sum > 100;

  const sliceCnt = isMobile ? 4 : 6;

  const maxYear = moment().year() + 10;

  if (isEmptyValues(data)) {
    return (
      <Message text="Не указана" />
    )
  }

  return (
    <table className="table table-hove table-felix table-team-load__container">
      <tbody>
        {data.map(({ year }, i) => {
          const rows = [];

          for (let j = 0; j < monthsAll.length; j += sliceCnt) {
            const months = monthsAll.slice(j, j + sliceCnt);
            const isShowYear = j === 0; // в первой строке рисуем выбор года
            const isShowDelete = j === sliceCnt; // во второй строке рисуем кнопку удаления
            const isLastRow = j + sliceCnt === monthsAll.length;

            rows.push((
              <React.Fragment key={`${year}_${j}`}>
                <tr>
                  <th>
                    {isShowYear && (
                      i18n.t('employeeDataCreate.load.year')
                    )}
                    {isShowDelete && (data.length > 1 && !readonly) && (
                      <AcceptDelete
                        type="outline"
                        buttonType="text"
                        className="button_Link_borderNone_delete team-load-table__remove_btn"
                        onOk={() => deleteRow(i)}
                      >
                        <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash-alt" className="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path></svg>
                      </AcceptDelete>
                    )}
                  </th>
                  {months.map(monthName => (
                    <th key={monthName}>{monthName}</th>
                  ))}
                </tr>
                <tr className={cx({ "input-title__container": (i + 1) < data.length && isLastRow })}>
                  <td>
                    {isShowYear && (
                      <InputTable
                        value={year}
                        onChange={(newYear) => changeYear(i, newYear)}
                        max={maxYear}
                        min={2000}
                        readonly={readonly}
                        thousandSeparator={undefined}
                        suffix={undefined}
                      />
                    )}
                  </td>
                  {months.map((_, x) => {
                    const monthIndex = x + j;

                    return (
                      <td key={monthIndex}>
                        <InputTable
                          value={getDataByMonth(i, monthIndex)}
                          onChange={(val) => changeValue(i, monthIndex, val)}
                          readonly={readonly}
                          className={cx({ red: isOverload(i, monthIndex) })}
                        />
                      </td>
                    )
                  })}
                </tr>
              </React.Fragment>
            ))
          }

          return rows;
        })}
      </tbody>
    </table>
  )
}

export default TeamLoadTable;
