import React from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import './style.scss';
import { useAppSelector } from '@/utils/typedHooks';

const LoaderModal = () => {
  const isLoad = useAppSelector(state => state.Modal.loadCount > 0);

  return createPortal((
    <div className={cx("modal-load__container", { hide: !isLoad })}>
      <div className="modal-load__body">
        Загрузка...
      </div>
    </div>
  ), document.body);
}

export default LoaderModal;
