import moment from 'moment'
import { isEmpty, isFunction, sortBy, uniq } from 'lodash'
import { DictDataItem } from '@/reducers/Dictionaries';
import { getDictCodeById, getRound } from '@/utils';
import { BudgetIncomeIndicatorType } from '@/config/const';

export const sortBudget = (itemData) => ({
  ...itemData,
  plan: sortBy(itemData.plan, (item) => moment(item.effectiveDate).unix())
});

export const sortDatedBudget = (b1: BudgetDated, b2: BudgetDated) => {
  return moment(b1.effectiveDate).diff(moment(b2.effectiveDate));
}

export const getRoundFormat = (item: number, defaultValue = '-') => getRound(item) || defaultValue;

export const getSumByField = (arr: any[], field: string) => arr.reduce((acc, item) => acc + (item[field] || 0), 0);

export const monthList = [
  { label: 'январь', value: '01' },
  { label: 'февраль', value: '02' },
  { label: 'март', value: '03' },
  { label: 'апрель', value: '04' },
  { label: 'май', value: '05' },
  { label: 'июнь', value: '06' },
  { label: 'июль', value: '07' },
  { label: 'август', value: '08' },
  { label: 'сентябрь', value: '09' },
  { label: 'октябрь', value: '10' },
  { label: 'ноябрь', value: '11' },
  { label: 'декабрь', value: '12' }
]

export const isSkipFiltered = (item: BudgetDated, yearFilter: number) => {
  const effectiveDate = moment(item.effectiveDate);

  if (yearFilter === -1) {
    return false;
  }

  return +effectiveDate.format("YYYY") !== yearFilter;
};

export const isSkipFilteredBudgetYear = (item: BudgetYearPlanFact, yearFilter: number) => {
  if (yearFilter === -1) {
    return false;
  }

  return +item.year !== yearFilter;
};

export const getYearsFilter = (list: BudgetDated[]) => {
  return [
    {
      label: 'Все',
      value: -1
    },
    ...uniq(list.map(item => moment(item.effectiveDate).format("YYYY"))).map(year => ({
      label: year,
      value: +year
    }))
  ];
}

export const getYearsFilterBudgetYear = (list: BudgetYearPlanFact[]) => {
  return [
    {
      label: 'Все',
      value: -1
    },
    ...sortBy(uniq(list?.map(item => item.year))).map(year => ({
      label: year,
      value: +year
    }))
  ];
}

export const updateListByIndex = (list, index, value) => {
  return list.map((item, i) => {
    return i === index ? (isFunction(value) ? value(item) : value) : item;
  })
}

export const getDefaultBudget = (nextEffectiveDate: string = moment().format('YYYY-MM-DD')): BudgetPlanItem => ({
  okbFact: 0,
  okbPlan: 0,
  okbPrediction: 0,
  okbKfot: 0,
  ddsFact: 0,
  ddsPlan: 0,
  ddsPrediction: 0,
  ddsKfot: 0,
  effectiveDate: nextEffectiveDate,
  inputFact: 0,
  inputPlan: 0,
  inputPrediction: 0,
});

export const getDefaultBudgetOibda = (nextEffectiveDate: string = moment().month(11).date(1).format('YYYY-MM-DD')): BudgetOibdaItem => ({
  effectiveDate: nextEffectiveDate,
  plan: null,
  fact: null,
  prediction: null,
});

export const getSumBudgets = (
  budgetsInput: BudgetSourceItem[],
  projectData: Project,
  typeId?: number
): BudgetSumItem[] => {
  const budgets = budgetsInput
    .filter(item => !item.isForInfoOnly)
    .filter(item => typeId === undefined || item.typeId === typeId)
    .reduce((acc, item) => [...acc, ...item.plan], [])

  return Object.values(budgets.reduce((acc, budget) => {
    const year = Number(moment(budget.effectiveDate).format('YYYY'));

    if (!acc[year]) {
      acc[year] = {
        ddsPrediction: 0,
        ddsFact: 0,
        ddsPlan: 0,
        inputPrediction: 0,
        inputFact: 0,
        inputPlan: 0,
        okbPrediction: 0,
        okbFact: 0,
        okbPlan: 0,
        name: projectData.name,
        type: projectData.typeId,
        id: projectData.id,
        typeCode: projectData.projectTypeCode,
        year,
      }
    }

    acc[year] = {
      ...acc[year],
      ddsPrediction: acc[year].ddsPrediction + +budget.ddsPrediction,
      ddsFact: acc[year].ddsFact + +budget.ddsFact,
      ddsPlan: acc[year].ddsPlan + +budget.ddsPlan,
      inputPrediction: acc[year].inputPrediction + +budget.inputPrediction,
      inputFact: acc[year].inputFact + +budget.inputFact,
      inputPlan: acc[year].inputPlan + +budget.inputPlan,
      okbPrediction: acc[year].okbPrediction + +budget.okbPrediction,
      okbFact: acc[year].okbFact + +budget.okbFact,
      okbPlan: acc[year].okbPlan + +budget.okbPlan,
    }

    return acc;
  }, {}))
}

export const getBudgetNameTable = (budgetSourceType: DictDataItem): string => {
  if (!budgetSourceType) {
    return null;
  }

  if (budgetSourceType.code === 'CAPEX') {
    return 'ОКВ'
  }

  return budgetSourceType.name;
}

export const calcBudgetMargin = (incomeIndicators: BudgetIncomeIndicator[], budgetIncomeIndicatorTypeDict) => {
  if (isEmpty(incomeIndicators)) {
    return [];
  }

  const marginYear = incomeIndicators
    .filter(indicator => getDictCodeById(budgetIncomeIndicatorTypeDict, indicator.typeId) !== BudgetIncomeIndicatorType.INDIRECT_COST_SAVINGS)
    .reduce((acc, indicator) => {
      indicator.plan.forEach(plan => {
        const planDate = moment(plan.effectiveDate).startOf('month');
        const planYear = planDate.year();
        acc[planYear] = {
          plan: (+acc[planYear]?.plan || 0) + (+plan.plan || 0),
          fact: (+acc[planYear]?.fact || 0) + (+plan.fact || +plan.prediction || 0),
        }
      });

    return acc;
  }, {});

  const marginArr = [];
  Object.keys(marginYear).forEach(year => {
    marginArr.push({ year: +year, plan: marginYear[year].plan, fact: marginYear[year].fact });
  });

  return marginArr;
}