import { compact, get, intersection, isArray, isEmpty, isFunction, isPlainObject, trim } from 'lodash';
import moment from 'moment-timezone';
import { Dispatch, SetStateAction } from 'react';
import { FORMAT_DATE, FORMAT_DATE_HUMAN, FORMAT_DATE_SERVER, PpmRequestStatus, UserRight } from '@/config/const';
import { DictDataItem, DictItem } from '@/reducers/Dictionaries';
import { RootState } from '@/index';
import { SERVER_TIME_ZONE } from '@/utils/clockmanager';

export const importExt = (path) => import(`#/${path}`).catch(() => null)?.then(module => module?.default);

export const getDictObj = (dict: DictItem, id: number): DictDataItem => {
  if (isEmptyValues(id) || isEmptyValues(dict)) {
    return null;
  }

  return dict.data.find(v => v.id == id); // eslint-disable-line eqeqeq
};

export const getDictObjList = (dict: DictItem, id: number | number[]): DictDataItem[] => {
  const idFind = [];
  if (isArray(id)) {
    idFind.push(...(id as number[]).filter(item => isNotEmptyValues(item)));
  } else (
    idFind.push(id)
  );

  if (isEmptyValues(idFind) || isEmptyValues(dict)) {
    return null;
  }

  return dict.data.filter(v => idFind.includes(v.id)); // eslint-disable-line eqeqeq
};

export const getDictObjListByCode = (dict: DictItem, code: string | string[]): DictDataItem[] => {
  const idFind = [];
  if (isArray(code)) {
    idFind.push(...(code as string[]).filter(item => isNotEmptyValues(item)));
  } else (
    idFind.push(code)
  );

  if (isEmptyValues(idFind) || isEmptyValues(dict)) {
    return null;
  }

  return dict.data.filter(v => idFind.includes(v.code)); // eslint-disable-line eqeqeq
};

export const getDict = (
  dict: DictItem,
  id: number | number[],
  labelGenerator = (item: DictDataItem) => item.name
): string => {
  if (id !== 0 && !id) {
    return null;
  }

  if (isArray(id)) {
    return (id as number[]).sort().map(item => getDict(dict, item)).join(', ');
  }

  const item = getDictObj(dict, id as number);

  if (item) {
    return labelGenerator(item);
  }

  return `${id} - (справочник не найден)`;
};

export const getDictByCode = (dict: DictItem, code: string): DictDataItem | null => {
  if (!code) {
    return null;
  }

  if (dict) {
    return dict.data.find(v => v.code === code) || null;
  }

  return null;
};

export const getDictCodeById = (dict: DictItem, id: number): string => {
  if (!dict) {
    return null;
  }

  const item = getDictObj(dict, id);
  if (!item) {
    return null;
  }

  return item.code;
};

export const getDictIdByCode = (dict: DictItem, code: string): number | null => {
  const result = getDictByCode(dict, code);

  if (result === null) {
    return null;
  }

  return result.id;
};

export const getDictForSelect = (
  dict: DictItem,
  labelGenerator = (item: DictDataItem) => item.name,
  filterFunc: (_: DictDataItem) => boolean = dictFilterActive,
) => dict?.data
    ? dict.data
      .filter(filterFunc)
      .map((type) => ({
        label: labelGenerator(type),
        value: type.id,
        desc: type.description,
        code: type.code,
      }))
    : [];

export const getDictForSelectTree = (dict: DictItem, filterFunc: (DictDataItem) => boolean = dictFilterActive): DictDataItem[] => {
  if (!dict?.data) {
    return [];
  }

  return dict.data.filter(filterFunc).map(item => ({
    ...item,
    children: item.children.filter(filterFunc),
  }));
};

const normalizateTreeValue = ({ data: { id, name, ...data }, children }) => {
  return {
    ...data,
    children,
    value: id ,
    label: name,
  };
};

const normalizateTree = (data) => {
  return data.map(({ data, children }) => {
    return normalizateTreeValue({
      data,
      children: normalizateTree(children)
    });
  });
};

export const getDictForSelectTree2 = (dict): DictDataItem[] => {

  if (!dict) {
    return [];
  }

  return normalizateTree(dict.data);
};

export const flattenTreeDict = (data: DictDataItem[]) => data.reduce((acc, item) => ([
  ...acc,
  item,
  ...((item.children && item.children.length) ? flattenTreeDict(item.children) : [])
]), []);

export const dictTreeToDict = (dict: DictItem): DictItem => {
  if (!dict || !dict.data.length) {
    return { ...dict };
  }

  return {
    ...dict,
    data: flattenTreeDict(dict.data).map((item, i) => ({
      id: item.value || item.id,
      name: item.label || item.name,
      code: item.code,
      sort: i,
    }))
  };
};

export const declOfNum = (number: number, words: string[]) => {
  const numberAbs = Math.abs(number);
  return words[(numberAbs % 100 > 4 && numberAbs % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(numberAbs % 10 < 5) ? numberAbs % 10 : 5]];
};

export const cntDeclOfNum = (number: number, words: string[]) => isEmptyValues(number)
  ? null
  : `${number} ${declOfNum(number, words)}`;

export const formatDays = (number: number) => cntDeclOfNum(number, ['день', 'дня', 'дней']);

export const getRound = (num: number, defaultValue: any = undefined) => {
  if (isEmptyValues(num)) {
    return defaultValue;
  }

  const res = Math.ceil(parseFloat((+num).toFixed(4)));

  return forrmattedNum(res, defaultValue);
};

export const forrmattedNum = (num: number, defaultValue: string = undefined) => {
  if (num || num === 0) {
    return num.toLocaleString('ru-RU');
  }

  return defaultValue;
};

export const toFixed = (num: number, size: number = 2, defaultValue: number = undefined): number => {
  if (isEmptyValues(num)) {
    return defaultValue;
  }

  return parseFloat(num.toFixed(size));
};

export const formatDateToServer = (data) => {
  return data ? moment(data).format(FORMAT_DATE_SERVER) : null;
};

export const addAllSet = (set: any, ...data: any) => {
  data.forEach((item: any) => set.add(item));
};

export const checkRight = (state: RootState, projectData = state.NewProject.newProjectData) => {
  const list = new Set<string>();

  addAllSet(list, ...get(state, "Login.currentUser.rightCodes", []));

  addAllSet(list, ...get(projectData, "userRightCodes", []));

  return (...rights: string[]): boolean => {
    return !isEmpty(intersection([...list], rights));
  };
};

export const ppmCheckRight = (state: RootState, ppmRequestData = state.PpmRequest.data) => {
  const list = new Set<string>();

  addAllSet(list, ...get(state, "Login.currentUser.rightCodes", []));

  addAllSet(list, ...get(ppmRequestData, "userRightCodes", []));

  return (...rights: string[]): boolean => {
    return !isEmpty(intersection([...list], rights));
  };
};

export const checkEdit = (state: RootState, projectData = state.NewProject.newProjectData) => {
  const isAccessStatus = !['COORDINATION', 'ARCHIVE']
    .includes(getDictObj(state.dict.status, projectData.statusId)?.code);
  const isClosed = projectData.isClosed;

  return isAccessStatus && !isClosed;
};

export const checkAccess = (state: RootState, projectData = state.NewProject.newProjectData) => {
  const isAccessRight = checkRight(state, projectData)("EDIT_PROJECT");
  const isEdit = checkEdit(state, projectData);

  return isAccessRight && isEdit;
};

export const ppmCheckAccess = (state: RootState, requestData = state.PpmRequest.data) => {
  const isEditRight = ppmCheckRight(state, requestData)(UserRight.PPM_REQUEST_EDIT, UserRight.PPM_REQUEST_EDIT_EXTENDED);
  const requestStatus = PpmRequestStatus[getDictCodeById(state.dict.ppmRequestStatus, requestData.statusId)];
  const isEditStatus = [PpmRequestStatus.DRAFT, PpmRequestStatus.REWORK].includes(requestStatus);

  return isEditRight && isEditStatus;
};

export const formatDateWithFormat = (data, format = FORMAT_DATE) => {
  if (data == null) {
    return;
  }

  return moment.tz(data, "UTC").tz("Europe/Moscow").format(format);
};

export const selectDateToMoment = (date: DateServer): moment.Moment =>
  date ? moment({ ...date, month: date.month - 1 }) : null;

export const momentToSelectDate = (date: moment.Moment): any => date ? ({
  day: date.date(),
  month: date.month() + 1,
  year: date.year()
}) : date;

export const selectDateAddDelta = (date: DateServer,
  delta: number,
  unit: moment.unitOfTime.DurationConstructor = 'days') => {
  return date ? momentToSelectDate(selectDateToMoment(date).add(delta, unit)) : date;
}
export const formatDate = (date: any, format = FORMAT_DATE_HUMAN, emptyText = undefined) => {
  const result = parseDate(date);

  if (result === null) {
    return emptyText;
  }

  return result.format(format);
};

export const parseDate = (date: moment.MomentInput | DateServer, isWithTime: boolean = false): moment.Moment => {
  if (!date) {
    return null;
  }

  if (isPlainObject(date)) {
    return selectDateToMoment(date as DateServer);
  }

  if (isWithTime) {
    return moment.tz(date, SERVER_TIME_ZONE);
  }

  return moment(date);
};

export const sortDate = (a, b, order) => {
  const orderType = order === 'asc' ? 1 : -1;

  let sort;
  if (!a && !b) {
    sort = 0;
  } else if (!a && b) {
    sort = -1;
  } else if (a && !b) {
    sort = 1;
  } else {
    sort = moment(a.localDate).diff(moment(b.localDate));
  }

  return sort * orderType;
}

export const prevent = (func) => (e) => {
  e.preventDefault();
  e.stopPropagation();
  return func(e);
};

export const isEmptyValues = (value: any) =>
  value === undefined
  || value === null
  || value === NaN // eslint-disable-line use-isnan
  || ((typeof value === 'object') && Object.keys(value).length === 0)
  || ((typeof value === 'string') && value.trim().length === 0);

export const isEmptyDate = (value: any) => !value || !value.from || !value.to;

export const isNotEmptyValues = (value: any) => !isEmptyValues(value);

export const removeUndefinedObj = (obj) => Object.entries(obj).reduce((acc, [key, value]) => {
  if (value !== undefined) {
    acc[key] = value;
  }

  return acc;
}, {});

export const NO_SPECIFIED = 'Не указано';

export const getPaybackPeriodStr = (num: any): string => {
  if (!num || num === '.' || num === 0 || num === '0') {
    return NO_SPECIFIED;
  }

  const year = Math.floor(num);
  const mounth = Math.round(12 * (num % 1));

  return compact([
    cntDeclOfNum(year === 0 ? null : year, ['год', 'года', 'лет']),
    cntDeclOfNum(mounth === 0 ? null : mounth, ['месяц', 'месяца', 'месяцев']),
  ]).join(' и ');
};

export const updateByKeyGenerator = <S>(setData: Dispatch<SetStateAction<S>>) =>
  (key: string, value: any) =>
    setData(prevData => ({
      ...prevData,
      [key]: isFunction(value) ? value(prevData[key], prevData) : value
    }));

export const updateObjectFields = (obj, fields, updateFunc = trim) => {
  const result = { ...obj };

  fields.forEach(item => {
    if (result[item]) {
      result[item] = updateFunc(result[item]);
    }
  });

  return result;
};

export const trimObjectFeilds = (obj, fields) => {
  const result = { ...obj };

  fields.forEach(item => {
    if (result[item]) {
      result[item] = trim(result[item]);
    }
  });

  return result;
};

export const isBetweenTwoNumberWithNull = (val: number, min: number, max: number) => {
  if (isEmptyValues(val) && isEmptyValues(min) && isEmptyValues(max)) {
    return true;
  }

  if (isNotEmptyValues(val) && isNotEmptyValues(min) && isEmptyValues(max)) {
    return val >= min;
  }

  if (isNotEmptyValues(val) && isEmptyValues(min) && isNotEmptyValues(max)) {
    return val <= max;
  }

  return val >= min && val <= max;
};

export const isBetweenDate = (
  date: moment.MomentInput | DateServer,
  from: moment.MomentInput | DateServer,
  to: moment.MomentInput | DateServer
): boolean => {
  const dateParsed = parseDate(date);

  if (dateParsed === null) {
    return false;
  }

  const fromParsed = parseDate(from);
  const toParsed = parseDate(to);

  return dateParsed.isSame(fromParsed) || dateParsed.isBetween(fromParsed, toParsed);
};

export const getPrevWeekDay = (weekDay, from = moment()) => {
  const today = from.isoWeekday();

  if (today >= weekDay) {
    return from.clone().isoWeekday(weekDay);
  } else {
    return from.clone().add(-1, 'weeks').isoWeekday(weekDay);
  }
}

export const getNextWeekDay = (weekDay, from = moment()) => {
  const today = from.isoWeekday();

  if (today <= weekDay) {
    return from.clone().isoWeekday(weekDay);
  } else {
    return from.clone().add(1, 'weeks').isoWeekday(weekDay);
  }
}

export const truncNumber = (decimal: number, n: number = 2) => {
  const x = decimal + '';
  return x.lastIndexOf('.') >= 0
    ? parseFloat(x.substring(0, x.lastIndexOf('.') + (n + 1)))
    : decimal;
};

export const tWithMobile = (name, isMobile) =>
  `${name}${isMobile ? 'Mobile' : ''}`;

export const concatIfNotEmpty = (value, item) => !isEmptyValues(value) ? `${value}${item}` : null;

export const boolToText = (fl: boolean) => fl ? 'Да' : 'Нет';

export const isSelectDate = (data: any) => {
  if (isEmptyValues(data)) {
    return false;
  }

  const keys = Object.keys(data);

  return ['day', 'month', 'year'].every(item => keys.includes(item));
};

export const dictFilterActive = (item: DictDataItem): boolean => item.isActive !== false;

export const getVersionHistoryName = (item: VersionHistory) => {
  if (!item) {
    return '';
  }

  return compact([
    `v.${item.version}`,
    formatDateWithFormat(item.dateAgreement || item.dateUpdate, 'DD.MM.YY'),
    item.statusName,
  ]).join(' ');
}

export const formatWithThousandRubles = (input) => input ? `${input} тыс. руб.` : '---';

export const formatWithPercentage = (input) => input ? `${input}%` : '---';

export const formatEmptyIfNull = (input) => input ? `${input}` : '---';

export const formatWithYears = (input) => input ? `${input} лет` : '---';
export const formatAddLastTwoDigitsOfYear = (input, date: Date, plusYears: number = 0) => {
  if (!date || !input) {
    return '---';
  }
  return `${input} ${(date.getFullYear() + plusYears).toString().slice(2)}`
}

export const getUserSelectFromUserWeb = (user: UserWeb) => {
  if (!user) {
    return null;
  }
  return {
    id: user.id,
    label: compact([user.displayName, user.department, user.post]).join(' / '),
    value: user.login,
    displayName: user.displayName
  }
}

export const getAverage = (arr: number[]) => {
  return arr.reduce((prev, current) => prev + current, 0) / arr.length;
}

export const riskValueToDict = (riskValue: number) => {
  if (riskValue <= 2) {
    return {type: 'LOW', text: 'Низкая'};
  }
  if (riskValue <= 5) {
    return {type: 'AVERAGE', text: 'Средняя'};
  }
  return {type: 'HIGH', text: 'Высокая'};
}

export const addRiskValueDict = (riskData: any[]) => {
  if (!riskData) {
    return riskData;
  }
  return riskData.map(risk =>
    ({riskValueDict: riskValueToDict(risk.value), ...risk}));
}

export const isBeforeToday = (date) => {
  if (!date) {
    return false;
  }
  return parseDate(date).startOf('day').isBefore(moment().startOf('day'));
}

export const getFilterKey = () => `${window.location.pathname}@`;

export const emptyFunc = () => {};

export const getFileSize = (fileSizeByte) => {
  if (!fileSizeByte) {
    return '';
  }
  if (fileSizeByte/1024 < 1024) {
    return `${(fileSizeByte/1024).toFixed(2)} Кб`
  }
  return `${(fileSizeByte/(1024 * 1024)).toFixed(2)} Мб`
}

export const secondsToReadableTime = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  let ret = "";
  if (h > 0) {
    ret += "" + h + ":" + (m < 10 ? "0" : "");
  }
  ret += "" + m + ":" + (s < 10 ? "0" : "");
  ret += "" + s;
  return ret;
}

export const getProjectTypeNameShort = (code: string, customNameTab: any = {}) => {
  const projectTypeShortName = {PROJECT: customNameTab?.PROJECT ?? 'Проект',
    PROGRAM: customNameTab?.PROGRAM ?? 'Программа',
    PORTFOLIO: customNameTab?.PORTFOLIO ?? 'Портфель'};
  return projectTypeShortName[code] ?? null;
}

export const isRoleHidden = (roleDictItem) => roleDictItem?.rightsDict.includes(UserRight.HIDE_SELECT_IN_TEAM);

export const getProjectRoleOptionsList = (roleDict, projectTypeCode = 'PROJECT') => {
  const isRoleHidden = (roleDictItem) => roleDictItem?.rightsDict.includes(UserRight.HIDE_SELECT_IN_TEAM);

  return getDictForSelect({
    ...roleDict,
    data: roleDict.data.filter(item => {
      return item.typeCode === 'PROJECT'
        && (item.projectTypes.length == 0 || item.projectTypesEnum.includes(projectTypeCode))
        && !isRoleHidden(item);
    })
  });
}

export const getLicenseData = (state: RootState) => ({
  licenseUserCnt: state.dict.properties.data['license.userCnt'],
  licenseDateEnd: state.dict.properties.data['license.dateEnd'] ? moment(state.dict.properties.data['license.dateEnd']) : null,
  licenseNotifyAfterDays: state.dict.properties.data['license.notifyAfterDays'],
});

export const getParams = (searchParams) => {
  const result = {};
  for (const [key, value] of searchParams.entries()) {
    result[key] = value;
  }

  return result;
};