import { Tooltip as BTooltip, OverlayTrigger } from 'react-bootstrap'
import React from 'react';
import { useDeviceContext } from "@/context/DeviceContext";
import cx from "classnames";

const Icon = ({ important, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5003 7.00065C12.5003 10.0386 10.0376 12.5013 6.99967 12.5013C3.96175 12.5013 1.49902 10.0386 1.49902 7.00065C1.49902 3.96273 3.96175 1.5 6.99967 1.5C10.0376 1.5 12.5003 3.96273 12.5003 7.00065ZM14.0003 7.00065C14.0003 10.867 10.866 14.0013 6.99967 14.0013C3.13332 14.0013 -0.000976562 10.867 -0.000976562 7.00065C-0.000976562 3.1343 3.13332 0 6.99967 0C10.866 0 14.0003 3.1343 14.0003 7.00065ZM6.19932 3L7.79932 3V4.57714H6.19932V3ZM6.19932 6H7.79932V8.22286L7.79932 11H6.19932V8.22286V6Z"
      fill={important ? '#70f' : '#101828'}
      fillOpacity={important ? '0.5' : '0.25'}
    />
  </svg>
);

const Tooltip = ({
  text,
  placement = undefined,
  maxWidth = undefined,
  important = false,
  children = undefined,
  delay = undefined,
  className = undefined,
  width = undefined,
  height = undefined,
  icon = undefined,
  style = undefined
}, ref) => {

  const { isMobile } = useDeviceContext();
  if (!text) {
    return null;
  }

  const maxWidthWithMobile = isMobile ? '250px' : '300px';

  return (
    <OverlayTrigger
      placement={placement || 'right'}
      delay={{ show: delay, hide: 0 }}
      overlay={
        <BTooltip className="rt-tooltip__container">
          <div style={{ maxWidth: maxWidth || maxWidthWithMobile }}>
            {text}
          </div>
        </BTooltip>
      }
    >
      {children || (
        <div ref={ref} style={style} className={cx("rt-range-title__tooltip", className)}>
          {icon ? icon : <Icon important={important} width={width || "14"} height={height || "15"}/>}
        </div>
      )}
    </OverlayTrigger>
  );
}

export default React.forwardRef(Tooltip);
