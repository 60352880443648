import { getDict } from '@/utils'
import i18n from "i18next";

const AgreementMboBlock = async (self) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.mbo) {
        return []
      }

      return data.mbo.map(item => ({
        title: item.goal,
        data: [
          {
            title: i18n.t('mboDataRequest.statusId'),
            data: getDict(self.props.dict.mboStatus, item.statusId)
          },
          {
            title: i18n.t('mboDataRequest.mboBase'),
            data: item.mboBase
          },
          {
            title: i18n.t('mboDataRequest.goalTypeId'),
            data: getDict(self.props.dict.mboGoalType, item.goalTypeId)
          },
          {
            title: i18n.t('mboDataRequest.user'),
            data: item.user.displayName
          },
          {
            title: i18n.t('mboDataRequest.performance'),
            data: item.performance
          },
          {
            title: i18n.t('mboDataRequest.periodAndQuarterId'),
            data: `${item.period} - ${getDict(self.props.dict.mboQuarter, item.quarterId)}`
          },
          {
            title: i18n.t('mboDataRequest.performancePercent'),
            data: item.performancePercent
          },
          {
            title: i18n.t('mboDataRequest.comment'),
            data: item.comment
          },
          {
            title: i18n.t('mboDataRequest.weight'),
            data: item.weight
          },
        ]
      }))
    }
  },
]);

export default AgreementMboBlock;
