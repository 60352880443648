import React, { Fragment } from 'react';
import Select from '@/elements/Select';
import services from '@/services';
import { isEmpty, flatten } from "lodash";
import service from "@/services";
import { connect } from "react-redux";
import { Dict } from "@/reducers/Dictionaries";
import cx from 'classnames';
import { prepareFilesToSend } from '@/services/fileLoader';
import DocModal from "@/components/Doc/DocModal";
import { modalShow } from '@/actions/modal';
import { getFilesPpmRequest, getFilesProject } from '@/components/Doc/docList';

const mb30 = 31_457_280;
const fileSizeDescription = 'Размер прикрепляемого файла не должен превышать 30 Мб';

const sendChunks = (fileContentId, chunks) => {
  return Promise.all(chunks.map((chunk, i) => service.post('/file/chunk', {
    fileContentId,
    chunkIndex: i,
    content: chunk,
  }))).then(() => service.post(`/file/merge/${fileContentId}`));
}

export const sendFilesToServer = async (ownerId, externalId, files, fileTypeDict) => {
  const filesToSend: any[] = await prepareFilesToSend(ownerId, externalId, files, fileTypeDict);
  return services.post('/file/batch', filesToSend.map(f => ({...f, chunks: undefined})))
    .then(data => Promise.all(filesToSend.filter(f => f.md5).map(f => sendChunks(data[f.md5], f.chunks))))
}

export interface ISaveDocProps {
  dict?: Dict;
  showMessage?: any;
  projectId?: number;
  ppmRequestId?: number;
  _onStateChanged?: any;
  disabled?: any;
  externalId?: number;
  typeCode: string;
  label?: string;
  ignoreOwnerFiles?: boolean;
  required?: boolean | string;
  tooltip?: string | boolean;
  description?: string;
  descriptionInOptions?: boolean;
  afterUpdate?: any;
  componentType?: undefined | 'select' | 'button';
  isMobile?: boolean;
  hideFileType?: boolean;
  defaultTypeCode?: string;
}

interface ISaveDocState {
  items: any[];
  currentDocType: any;
  types: any[];
  ownerFiles: any[];
  checkedFiles: any[];
  isFilesLoaded: boolean;
}

class SaveDoc extends React.Component<ISaveDocProps, ISaveDocState> {
  modalRef: any;

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      currentDocType: null,
      types: [],
      ownerFiles: [],
      checkedFiles: [],
      isFilesLoaded: false,
    }

    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.getSelectData();
    this.getOwnerFilesInfo();
  }

  componentDidUpdate(_, prevState: Readonly<any>): void {
    if (this.state !== prevState && this.props._onStateChanged) {
      this.props._onStateChanged()
    }
  }

  changeTypeId = (val) => {
    this.setState({currentDocType: val});
  }

  getItems = () => {
    return this.state.items;
  }

  setItems = (items) => {
    this.setState({
      items: items,
    });
  }

  isDisabledSelect = () => {
    return this.props.disabled
      || (this.state.currentDocType == null )
      || !this.state.isFilesLoaded;
  }

  getTypeInfoById = (id) => {
    return this.getOptions().find(type => type.value === id);
  }

  getOptions = () => {
    return this.state.types;
  }

  getSelectData = () => {
    return services
      .get(`/dict/project/file?sectionCode=${this.props.typeCode}`)
      .then((body) => {
        const types: any[] = body.map((type) => ({
          label: type.name,
          value: type.id,
          description: type.description,
          typeCode: type.code
        }));
        if (this.props.componentType === 'button') {
          this.setState({
            types: types,
            currentDocType: types.find(value => value.typeCode === this.props.defaultTypeCode ?? 'OTHER')?.value ?? types[0]?.value
          });
        } else {
          this.setState({
            types: types
          });
        }
      })
      .catch((error) => {
        console.error('Could not fetch', error);
      });
  }

  sendFiles = async (ownerId, externalId) => {
    return sendFilesToServer(ownerId, externalId, this.getItems(), this.props.dict.fileType)
      .then(() => this.getOwnerFilesInfo())
      .then(() => this.reset());
  }

  reset = () => {
    this.setState(prev => ({
      items: [],
      currentDocType: this.props.componentType === 'button' ? prev.currentDocType : null
    }));
  }

  selectedFile = (files) => {
    if (!files.length) {
      return;
    }

    for (const file of files) {
      if (file.size > mb30) {
        this.props.showMessage('Внимание. Размер прикрепляемого файла не должен превышать 30 Мб');
        return;
      }
    }

    this.setState(prev => ({
      currentDocType: this.props.componentType === 'button' ? prev.currentDocType : null,
      items: [
        ...this.state.items,
        ...[...files].map(item => ({
          key: Math.random(),
          typeId: this.state.currentDocType,
          name: item.name,
          file: item
        }))
      ]
    }), () => {
      if (this.props.afterUpdate) {
        this.props.afterUpdate();
      }
    });
  }

  addLink = (url, urlName) => {
    if (!url) {
      return;
    }
    this.setState(prev => ({
      currentDocType: this.props.componentType === 'button' ? prev.currentDocType : null,
      items: [
        ...this.state.items,
        {
          key: Math.random(),
          typeId: this.state.currentDocType,
          name: !!urlName ? urlName : url,
          isLink: true,
          link: url
        }
      ]
    }), () => {
      if (this.props.afterUpdate) {
        this.props.afterUpdate();
      }
    })
  }

  selectedOldFile = (files) => {
    if (!files.length) {
      return;
    }

    this.setState(prev => ({
      currentDocType: this.props.componentType === 'button' ? prev.currentDocType : null,
      items: [
        ...this.state.items,
        ...[...files].map(item => ({
          key: Math.random(),
          typeId: this.state.currentDocType,
          name: item.name,
          fileContentId: item.fileContentId
        }))
      ]
    }), () => {
      if (this.props.afterUpdate) {
        this.props.afterUpdate();
      }
    });
  }

  isEmpty = () => !this.state.items.length

  removeFile = (file) => {
    this.setState(prev => ({
      currentDocType: this.props.componentType === 'button' ? prev.currentDocType : null,
      items: this.state.items.filter(item => item !== file)
    }), () => {
      if (this.props.afterUpdate) {
        this.props.afterUpdate();
      }
    })
  }

  openModal = () => {
    this.modalRef.current.open();
  }

  getOwnerFilesInfo = async () => {
    if (this.props.ignoreOwnerFiles) {
      this.setState({isFilesLoaded: true});
      return;
    }

    if (!this.props.projectId && !this.props.ppmRequestId) {
      this.setState({isFilesLoaded: false});
      return;
    }

    const data = [];
    if (this.props.projectId) {
      data.push(...(await getFilesProject(this.props.projectId)))
    }
    if (this.props.ppmRequestId) {
      data.push(...(await getFilesPpmRequest(this.props.ppmRequestId)))
    }

    if (isEmpty(flatten(data))) {
      this.setState({isFilesLoaded: true});
      return;
    }
    const uniqueFilesData = data.filter(
      (value, index) => data.findIndex(
        file => file.fileContentId === value.fileContentId) === index);
    this.setState({
      ownerFiles: uniqueFilesData,
      isFilesLoaded: true,
    });
  }

  getDescription = (typeId) => {
    if (this.props.description && typeof this.props.description === 'string') {
      return this.props.required
        ? `Обязательно к заполнению. ${fileSizeDescription}${this.props.description
          ? '. ' + this.props.description
          : ''}`
        : `${fileSizeDescription}. ${this.props.description}`;
    }
    if (this.props.description && typeId) {
      return `${fileSizeDescription}. ${this.state.types.find(type => type.value === typeId)?.description}`;
    }
    return this.props.required ? `Обязательно к заполнению. ${fileSizeDescription}` : fileSizeDescription;
  };

  render() {
    const isMobile = this.props.isMobile;
    return (
      <Fragment>
        <DocModal ref={this.modalRef}
                  isByOwner={this.props.projectId || this.props.ppmRequestId}
                  addLink={this.addLink}
                  fileList={this.state.ownerFiles}
                  isDisabledSelect={this.isDisabledSelect()}
                  selectedOldFile={this.selectedOldFile}
                  selectedFile={this.selectedFile}
        />

        {this.props.componentType === 'select'
          || !this.props.componentType
          && <Fragment>
            <div className="flex-space-between saveDoc">
              <div style={{width: '100%'}}>
                <Select
                  tooltip={this.props.tooltip}
                  options={this.getOptions()}
                  label={this.props.label || "Выбрать тип документа"}
                  value={this.state.currentDocType}
                  name="typeFileId"
                  onChange={this.changeTypeId}
                  type="typeFileId"
                  disabled={this.props.disabled}
                  descriptionInOptions={this.props.descriptionInOptions}
                />
                <div className={cx('input-title__description',
                  'doc-required-description',
                  {red: this.props.required === 'red' && this.isEmpty()})}>
                  {this.getDescription(this.state.currentDocType)}
                </div>
              </div>

              <div className={`input-file-felix ${this.isDisabledSelect() ? 'disable' : ''}`}>
                <label className="rt-custom-file-label" htmlFor="inputGroupFileIsAnyFiles">
                  Прикрепить
                </label>
                <input
                  disabled={this.isDisabledSelect()}
                  onClick={() => this.openModal()}
                  id="inputGroupFileIsAnyFiles"
                  aria-describedby="inputGroupFileAddon04"
                />
              </div>

            </div>
            <div className='marginLeft-12'>
              {this.state.items.map((file) => (
                <div key={file.key}>
                  <span>{file.name} ({this.getTypeInfoById(file.typeId)?.label})</span>
                  <label className="input-file-felix__remove">
                    <span>x</span>
                    <input hidden type="button" onClick={() => this.removeFile(file)}/>
                  </label>
                </div>
              ))}
            </div>
          </Fragment>
        }

        {this.props.componentType === 'button' &&
          <Fragment>
            <div className={isMobile
              ? 'marginRight-6 display-flex flex-column'
              : 'gap-2 marginRight-6 display-flex flex-nowrap'}>
              <div className={`input-file-felix ${this.isDisabledSelect() ? 'disable' : ''}`}>
                <label className='rt-custom-file-label'
                       htmlFor='inputGroupFileIsAnyFiles' style={{marginBottom: '0.2rem', marginLeft: '0'}}>
                  Прикрепить
                </label>
                <input
                  disabled={this.isDisabledSelect()}
                  onClick={() => this.openModal()}
                  id='inputGroupFileIsAnyFiles'
                  aria-describedby='inputGroupFileAddon04'
                />
              </div>
              <div className={cx('input-title__description',
                {red: this.props.required === 'red' && this.isEmpty()})}>
                {this.getDescription(this.state.currentDocType)}
              </div>

            </div>
            <div className='marginLeft-12'>
              {this.state.items.map((file) => (
                <div key={file.key}>
                  {this.props.hideFileType
                    ? <span>{file.name}</span>
                    : <span>{file.name} ({this.getTypeInfoById(file.typeId)?.label})</span>}
                  <label className="input-file-felix__remove">
                    <span>x</span>
                    <input hidden type="button" onClick={() => this.removeFile(file)}/>
                  </label>
                </div>
              ))}
            </div>
          </Fragment>
        }
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  dict: state.dict,
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message) => dispatch(modalShow(message)),
});
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(SaveDoc);
