import React from 'react';
import ButtonToVersion from '@/components/Helper/ButtonToVersion';
import ButtonBack from '@/components/Helper/ButtonBack';
import { getPathByType } from '@/utils/project';
import { useAppSelector } from '@/utils/typedHooks';
import { checkAccess, getDictCodeById } from '@/utils';
import { useSelector } from 'react-redux';
import { useCheckRight } from "@/utils/hooks";
import { ProjectStatus, UserRight } from '@/config/const';

const HeaderButtons = ({ backTo = ".." }) => {
  const [
    projectTypeCode,
    projectVersionNextId,
    projectNextStatusCode,
    isEdit,
    projectVersionReleaseId,
    projectCurrentStatusId,
    statusDict
  ] = useAppSelector(state => [
    state.NewProject.newProjectData.projectTypeCode,
    state.NewProject.newProjectData.projectVersionNextId,
    state.NewProject.newProjectData.projectNextStatusCode,
    state.NewProject.newProjectData.edit,
    state.NewProject.newProjectData.projectVersionReleaseId,
    state.NewProject.newProjectData.statusId,
    state.dict.status
  ])
  const isAccess = useSelector(checkAccess);
  const checkRight = useCheckRight();

  const hasAccessToAgreement = checkRight(UserRight.AGREE_PROJECT, UserRight.REJECT_PROJECT)
    && projectNextStatusCode === ProjectStatus.COORDINATION;

  const projectCurrentStatusCode = getDictCodeById(statusDict, projectCurrentStatusId);

  return (
    <div className="project-back__container wrapper-option">
      <ButtonBack className="project-back__button" to={backTo} />
      <ButtonToVersion
            nextStatusCode={projectNextStatusCode}
            projectTypeCode={projectTypeCode}
            projectVersionNextId={projectVersionNextId}
            isAccess={isAccess}
            hasAccessToAgreement={hasAccessToAgreement}
            currentStatusCode={projectCurrentStatusCode}
            projectVersionReleaseId={projectVersionReleaseId}
            isEdit={isEdit}/>
    </div>
  )
}

export default HeaderButtons;
