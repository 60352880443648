import moment from "moment";

export const SAUSAGE_HEIGHT = 42;

export const ItemTypes = {
  SAUSAGE: 'SAUSAGE',
  RESIZE_RIGHT: 'RESIZE_RIGHT',
  RESIZE_LEFT: 'RESIZE_LEFT',
  CONNECT_LEFT: 'CONNECT_LEFT',
  CONNECT_RIGHT: 'CONNECT_RIGHT',
};

export type LinkType = {
  from: boolean;
  to: boolean;
};

export enum LinkTypes {
  END_TO_START = 'END_TO_START',
  START_TO_START = 'START_TO_START',
  END_TO_END = 'END_TO_END',
  START_TO_END = 'START_TO_END',
};

export const LinkTypesData: {
  [key in LinkTypes]: LinkType;
} = {
  [LinkTypes.END_TO_START]: {
    from: false, to: true,
  },
  [LinkTypes.START_TO_START]: {
    from: true, to: true,
  },
  [LinkTypes.END_TO_END]: {
    from: false, to: false,
  },
  [LinkTypes.START_TO_END]: {
    from: true, to: false,
  },
};

export type ItemType = string;

export enum CalendarType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
};

export type CalendarTypeDataType = {
  width: number;
  momentType: moment.unitOfTime.DurationConstructor;
  momentFormat: string;
  headerWidth: (date: moment.Moment) => number;
  label: string;
};

export const CalendarTypeData: {
  [key: string]: CalendarTypeDataType;
} = {
  [CalendarType.DAY]: {
    width: 80,
    momentType: 'days',
    momentFormat: 'MMM DD',
    headerWidth: () => 80,
    label: 'Дни',
  },
  [CalendarType.WEEK]: {
    width: 27,
    momentType: 'week',
    momentFormat: 'MMM DD',
    headerWidth: () => 27 * 7,
    label: 'Недели',
  },
  [CalendarType.MONTH]: {
    width: 12,
    momentType: 'month',
    momentFormat: 'MMMM YYYY',
    headerWidth: (date) => 12 * date.daysInMonth(),
    label: 'Месяцы',
  },
  [CalendarType.QUARTER]: {
    width: 4,
    momentType: 'quarter',
    momentFormat: 'Q кв. YYYY',
    headerWidth: (date) => 4 * (date.clone().endOf('quarter').diff(date.clone().startOf('quarter'), 'days') + 1),
    label: 'Кварталы',
  },
  [CalendarType.YEAR]: {
    width: 1,
    momentType: 'year',
    momentFormat: 'YYYY',
    headerWidth: (date) => date.clone().endOf('year').diff(date.clone().startOf('year'), 'days') + 1,
    label: 'Годы',
  },
};