import * as React from 'react';

import SelectBase from './SelectBase';
import SelectCreateble from '@/elements/Select/SelectCreateble';

export const getStyles = ({ widthMenu,
  maxWidthMenu = undefined,
  widthInput,
  maxWidthInput = undefined,
  newTable,
  singleValuePaddingRight = undefined,
  backgroundColor = undefined,
  border = undefined,
  textColor = undefined
}) => ({
  menu: (provided) => ({
    ...provided,
    width: widthMenu,
    maxWidth: maxWidthMenu,
    padding: '0'
  }),
  singleValue: () => ({
    position: 'absolute',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    paddingRight: singleValuePaddingRight ?? 35,
    ...(textColor ? {color: textColor} : {})
  }),
  valueContainer: () => ({
    flexGrow: 1
  }),
  input: () => ({

  }),
  control: () => ({
    ...(newTable ? {} : {
      backgroundColor: backgroundColor ?? '#f1f4f6',
      border: border ?? '1px solid #f1f4f6',
      borderRadius: '8px',
      padding: '4px 8px',
    }),
    display: 'flex',
    width: widthInput,
    maxWidth: maxWidthInput,
  }),
  indicatorsContainer: () => ({

  }),
  loadingIndicator: () => ({
    display: 'none',
  }),
  placeholder: () => ({
    position: 'absolute'
  }),
  dropdownIndicator: () => ({
    color: 'hsl(0, 0%, 80%)'
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
});

interface ISelectInTableProps {
  widthInput?: number | string;
  widthMenu?: number | string;
  maxWidthInput?: number | string;
  maxWidthMenu?: number | string;
  newTable?: boolean;
  singleValuePaddingRight?: number | string;
  backgroundColor?: string;
  border?: string;
  textColor?: string;
  [key: string]: any;
}

const SelectInTable = ({
  widthInput = 80,
  widthMenu = 80,
  maxWidthInput = undefined,
  maxWidthMenu = undefined,
  newTable = false,
  singleValuePaddingRight = 35,
  backgroundColor = undefined,
  border = undefined,
  textColor = undefined,
  ...props
}: ISelectInTableProps) => {
  const styles = React.useMemo(
    () => getStyles(
      { widthInput,
        maxWidthInput, widthMenu, maxWidthMenu, newTable, singleValuePaddingRight, backgroundColor, border, textColor }),
    [widthInput, maxWidthInput, widthMenu, maxWidthMenu,
      newTable, singleValuePaddingRight, backgroundColor, border, textColor]
  );

  const Component = props.createble ? SelectCreateble : SelectBase;

  return (
    <Component
      {...props}
      styles={styles}
      menuPosition={'fixed'}
      menuPortalTarget={document.body}
    />
  );
};

export default SelectInTable;
