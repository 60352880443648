import service from '@/services';

export const getBasicNewProjectData = (id, cb = undefined) => {
  return async (dispatch) => {
    // должно выполнятся последовательно, ибо файлы смотрят на статус проекта!
    const projectData = await service.get(`/project/${id}`);
    projectData.files = await service.get(`/file/project/${id}`);


    dispatch({
      type: 'SET_NEW_PROJECT_DATA',
      payload: projectData,
    });

    if (cb) {
      cb(projectData);
    }

    return projectData
  };
};
