import { statusDict } from '@/actions/dict'

export const START_LOAD_DICT = "START_LOAD_DICT";
export const UPDATE_DICT = "UPDATE_DICT";

export const STATUS_NO_LOAD = "STATUS_NO_LOAD"
export const STATUS_LOAD = "STATUS_LOAD"
export const STATUS_LOADING = "STATUS_LOADING"

export interface DictDataItem {
  id: number;
  code: string;
  description?: string;
  isActive: boolean;
  name: string;
  nameRisk?: string;
  shortName?: string;
  sort?: number;
  rights?: number[];
  rightsDict?: string[];
  children?: DictDataItem[];
  typeCode?: string;
  star?: number;
  isExtended?: boolean;
  typeId?: number;
  type?: DictDataItem;
  correspondingSystemRole?: DictDataItem;
  ppmProjectType?: DictDataItem;
  color?: string;
  section?: DictDataItem;
  owner?: DictDataItem;
  projectTypes?: number[];
  projectTypesEnum?: string[];
  isWork?: boolean;
  isMilestone?: boolean;
  parent?: DictDataItem;
  minSum?: number;
  maxSum?: number;
}

export interface DictItem {
  status: string
  isLoad: boolean
  data: DictDataItem[]
}

export type Dict = {
  [Propery in typeof statusDict[number]]: DictItem
}

const initialState = () => statusDict.reduce((acc, key) => ({
  ...acc,
  [key]: {
    status: STATUS_NO_LOAD,
    isLoad: false,
    data: []
  }
}), {}) as Dict;

// fixme: any
const Dictionaries = (state: Dict = initialState(), action) => {
  switch (action.type) {
    case START_LOAD_DICT:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          status: STATUS_LOADING,
          isLoad: false,
        }
      }
    case UPDATE_DICT:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          status: STATUS_LOAD,
          isLoad: true,
          data: action.data
        }
      }

    default:
      return state;
  }
}

export default Dictionaries;
